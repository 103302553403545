import { User } from '../types/auth';
import { auth } from '../config/firebase';

// 生成 session token
export const generateSessionToken = async (user: User) => {
  try {
    const token = await user.getIdToken();
    localStorage.setItem('auth_token', token);
    return token;
  } catch (error) {
    console.error('Error generating token:', error);
    return null;
  }
};

// 驗證 token
export const verifySessionToken = async () => {
  try {
    const token = localStorage.getItem('auth_token');
    if (!token) return null;

    const currentUser = auth.currentUser;
    if (!currentUser) return null;

    // 刷新 token
    const newToken = await currentUser.getIdToken(true);
    localStorage.setItem('auth_token', newToken);
    
    return currentUser;
  } catch (error) {
    console.error('Error verifying token:', error);
    return null;
  }
};

// 清除 token
export const clearSessionToken = () => {
  localStorage.removeItem('auth_token');
}; 