import { DuckDuckGoSearchResult } from '../types/search';

export class SearchService {
  private static readonly SEARCH_API = process.env.REACT_APP_SEARCH_API || 'http://localhost:3020/api/search';
  
  static async searchWithDuckDuckGo(query: string): Promise<DuckDuckGoSearchResult[]> {
    try {
      console.log('[搜索服務] 開始搜索，API URL:', this.SEARCH_API);
      console.log('[搜索服務] 查詢參數:', query);

      const response = await fetch(`${this.SEARCH_API}?q=${encodeURIComponent(query)}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('[搜索服務] 響應錯誤:', {
          status: response.status,
          statusText: response.statusText,
          headers: Object.fromEntries(response.headers.entries()),
          error: errorText
        });
        throw new Error(`搜索請求失敗: ${response.status} ${response.statusText}`);
      }

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        console.error('[搜索服務] 響應類型錯誤:', {
          contentType,
          responseText: text.substring(0, 200) + '...'
        });
        throw new Error('響應格式錯誤：預期 JSON，實際收到 ' + contentType);
      }
      
      const results = await response.json();
      console.log('[搜索服務] 搜索結果:', results);
      
      return results.slice(0, 5);
    } catch (error) {
      console.error('[搜索服務] 錯誤:', error);
      throw error;
    }
  }
  
  static extractKeywords(message: string): string {
    return message;
  }
} 