import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StorageService } from '../services/storage';
import { ChatSession, Message } from '../types';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { auth } from '../config/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import type { User } from '../types/auth';

// 首先定義所有樣式組件
const SidebarContainer = styled.div<{ isCollapsed: boolean }>`
  width: ${props => props.isCollapsed ? '0' : '280px'};
  min-width: ${props => props.isCollapsed ? '0' : '280px'};
  height: 100vh;
  background: ${props => props.theme.background.sidebar};
  border-right: 1px solid ${props => props.theme.border.main};
  display: flex;
  flex-direction: column;
  padding: ${props => props.isCollapsed ? '0' : '2.5rem 1rem 1rem'};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  @media (max-width: 768px) {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 60px;
    z-index: 1000;
    width: ${props => props.isCollapsed ? '0' : '280px'};
    transform: translateX(${props => props.isCollapsed ? '-100%' : '0'});
  }
`;

const CollapseButton = styled.button<{ isCollapsed: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => props.theme.text.secondary};
  transition: all 0.2s ease-in-out;
  padding: 0;
  flex-shrink: 0;
  
  &:hover {
    background: ${props => props.theme.background.hover};
    color: ${props => props.theme.text.primary};
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const SearchContainer = styled.div<{ isCollapsed: boolean }>`
  display: ${props => props.isCollapsed ? 'none' : 'flex'};
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  background: ${props => props.theme.background.default};
  color: ${props => props.theme.text.primary};
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }
`;

const SessionList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
  margin-top: 0.25rem;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.border.main};
    border-radius: 2px;
  }
`;

// 1. 首先定義基本的 ActionButton
const ActionButton = styled.button`
  padding: 0.2rem;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  color: ${props => props.theme.text.secondary};
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:hover svg {
    color: ${props => props.theme.status.error};
  }
`;

// 2. 然後定義 StarButton
const StarButton = styled(ActionButton)<{ isStarred?: boolean }>`
  color: ${props => props.isStarred ? props.theme.status.warning : props.theme.text.secondary};
  opacity: ${props => props.isStarred ? 1 : 0};
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;

// 3. 定 DragHandle
const DragHandle = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.text.secondary};
  opacity: 0;
  cursor: grab;
  margin-right: 0.25rem;
  flex-shrink: 0;
  transition: opacity 0.2s ease;
  
  &:active {
    cursor: grabbing;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;

// 4. 然後定義 SessionActions
const SessionActions = styled.div`
  display: flex;
  gap: 0.25rem;
  margin-left: auto;
  padding-left: 0.25rem;
  flex-shrink: 0;

  ${ActionButton}:not(${StarButton}) {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  ${StarButton}[data-starred="true"] {
    opacity: 1;
  }
`;

// 5. 最後定義 SessionItem
const SessionItem = styled.div<{ selected?: boolean; isCollapsed: boolean }>`
  padding: 0.625rem 0.75rem;
  margin: 0.175rem 0.25rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: ${props => props.selected ? props.theme.primary.main + '20' : 'transparent'};
  color: ${props => props.selected ? props.theme.primary.main : props.theme.text.primary};
  position: relative;
  min-width: 0;
  
  &:hover {
    background: ${props => props.selected ? props.theme.primary.main + '30' : props.theme.secondary.hover};

    ${DragHandle} {
      opacity: 0.6;
      
      &:hover {
        opacity: 1;
      }
    }

    ${SessionActions} {
      ${ActionButton} {
        opacity: 0.6;
        
        &:hover {
          opacity: 1;
        }
      }

      ${StarButton}[data-starred="true"] {
        opacity: 1;
        color: ${props => props.theme.status.warning};
      }
    }
  }
`;

const SessionTitle = styled.div<{ isCollapsed: boolean }>`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: ${props => props.isCollapsed ? 'none' : 'block'};
  font-size: 0.875rem;
  line-height: 1.5;
  padding-right: 0.5rem;
  margin-right: auto;
`;

const SessionSummary = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.text.secondary};
  margin-top: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DeleteButton = styled(ActionButton)`
  color: ${props => props.theme.status.error};
`;

const NewChatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
`;

const NewChatButton = styled.button<{ isCollapsed: boolean; floating?: boolean }>`
  width: 36px;
  height: 36px;
  background: ${props => props.theme.primary.main};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  padding: 0;
  flex-shrink: 0;
  margin-left: auto;

  &:hover {
    background: ${props => props.theme.primary.dark};
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    width: 18px;
    height: 18px;
    stroke-width: 2;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: rotate(90deg);
  }
`;

const StarFilterButton = styled.button<{ isActive: boolean }>`
  padding: 0.5rem;
  background: ${props => props.isActive ? props.theme.primary.main : 'transparent'};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  cursor: pointer;
  opacity: ${props => props.isActive ? 1 : 0.6};
  
  &:hover {
    opacity: 1;
  }
`;

// 修改 CollapseIcon 使其更有設計感
const CollapseIcon = ({ isCollapsed }: { isCollapsed: boolean }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
      transition: 'transform 0.3s ease'
    }}
  >
    <g opacity={isCollapsed ? "1" : "0.7"}>
      <rect x="2" y="4" width="14" height="2" rx="1" fill="currentColor" />
      <rect x="2" y="8" width="14" height="2" rx="1" fill="currentColor" />
      <rect x="2" y="12" width="14" height="2" rx="1" fill="currentColor" />
    </g>
    <g opacity={isCollapsed ? "0.3" : "1"}>
      <rect x="6" y="4" width="10" height="2" rx="1" fill="currentColor" />
      <rect x="6" y="8" width="10" height="2" rx="1" fill="currentColor" />
      <rect x="6" y="12" width="10" height="2" rx="1" fill="currentColor" />
    </g>
  </svg>
);

// 添加新的圖標組件
const ChatIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
  </svg>
);

const PlusIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <line x1="12" y1="5" x2="12" y2="19" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </svg>
);

// 添加刪除圖標組件
const DeleteIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
  </svg>
);

// 添加星標圖標組件
const StarIcon = ({ filled }: { filled?: boolean }) => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill={filled ? "currentColor" : "none"} 
    stroke="currentColor" 
    strokeWidth="2"
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

// 修改 FloatingButtonsContainer 的位置和樣式
const FloatingButtonsContainer = styled.div<{ isCollapsed: boolean }>`
  position: fixed;
  left: ${props => props.isCollapsed ? '16px' : '280px'};
  top: 16px;
  display: ${props => props.isCollapsed ? 'flex' : 'none'};
  gap: 8px;
  z-index: 1001;
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: ${props => props.theme.background.default};
  padding: 4px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    left: ${props => props.isCollapsed ? '16px' : '280px'};
    top: 16px;
    position: fixed;
  }
`;

// 修改 Copyright 樣式組件
const Copyright = styled.div`
  padding: 1rem;
  color: ${props => props.theme.text.secondary};
  font-size: 0.75rem;
  text-align: center;
  border-top: 1px solid ${props => props.theme.border.main};
  background: ${props => props.theme.background.paper};
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  user-select: none;
  
  // 添加漸變效果
  background: linear-gradient(
    to right,
    ${props => props.theme.text.secondary}10,
    ${props => props.theme.text.disabled}10
  );
  
  // 添加動畫效果
  transition: all 0.3s ease;
  
  &:hover {
    opacity: 1;
  }
`;

// 修改 CompanyLogo 樣式組件
const CompanyLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  color: ${props => props.theme.primary.main};
  font-size: 0.875rem;
  user-select: none;
`;

const LogoImage = styled.img`
  width: 24px;
  height: auto;
  transform: rotate(0deg);
  
  ${CompanyLogo}:hover & {
    transform: rotate(360deg);
    transition: transform 1.5s ease;
  }
`;

// 添加拖動時的樣式
const DraggableSessionItem = styled(SessionItem)<{ isDragging: boolean }>`
  background: ${props => props.isDragging ? props.theme.primary.main + '30' : 'transparent'};
  transform: ${props => props.isDragging ? 'scale(1.02)' : 'none'};
  transition: ${props => props.isDragging ? 'none' : 'all 0.2s ease'};
  
  &:hover {
    background: ${props => props.isDragging ? 
      props.theme.primary.main + '30' : 
      props.theme.secondary.hover};
  }
`;

// 添加拖動手柄圖標
const DragHandleIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="9" cy="6" r="2" />
    <circle cx="9" cy="12" r="2" />
    <circle cx="9" cy="18" r="2" />
    <circle cx="15" cy="6" r="2" />
    <circle cx="15" cy="12" r="2" />
    <circle cx="15" cy="18" r="2" />
  </svg>
);

// 添加新的 RefreshIcon 組件在其他圖標組件附近
const RefreshIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M21 2v6h-6M3 12a9 9 0 0 1 15-6.7L21 8M3 22v-6h6M21 12a9 9 0 0 1-15 6.7L3 16" />
  </svg>
);

// 在 SessionActions 樣式組件中添加新的按鈕樣式
const RefreshButton = styled(ActionButton)`
  &:hover svg {
    color: ${props => props.theme.primary.main};
  }
`;

// 修改 GoogleLoginButton 組件的樣式
const GoogleLoginButton = styled.button`
  width: 100%;
  padding: 0.75rem 1rem;
  margin: 0.5rem 0;
  background: ${props => props.theme.mode === 'dark' ? props.theme.background.paper : 'white'};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  color: ${props => props.theme.mode === 'dark' ? props.theme.text.primary : '#3c4043'};
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  position: relative;
  overflow: hidden;

  // 在暗色模式下添加微妙的光暈效果
  ${props => props.theme.mode === 'dark' && `
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.05)
      );
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  `}

  &:hover {
    background: ${props => props.theme.mode === 'dark' 
      ? props.theme.background.hover 
      : '#f8f9fa'};
    border-color: ${props => props.theme.mode === 'dark'
      ? props.theme.primary.main
      : '#dadce0'};
    box-shadow: ${props => props.theme.mode === 'dark'
      ? `0 0 0 1px ${props.theme.primary.main}40,
         0 2px 8px ${props.theme.primary.main}20`
      : '0 1px 3px rgba(60, 64, 67, 0.3)'};

    &::before {
      opacity: 1;
    }
  }

  &:active {
    background: ${props => props.theme.mode === 'dark'
      ? props.theme.background.hover 
      : '#f1f3f4'};
    border-color: ${props => props.theme.mode === 'dark'
      ? props.theme.primary.main
      : '#dadce0'};
    box-shadow: ${props => props.theme.mode === 'dark'
      ? `0 0 0 1px ${props.theme.primary.main}60`
      : '0 1px 2px rgba(60, 64, 67, 0.2)'};
  }

  svg {
    width: 20px;
    height: 20px;
    // 在暗色模式下稍微調整 Google Logo 的亮度
    ${props => props.theme.mode === 'dark' && `
      filter: brightness(1.1);
    `}
  }
`;

// 修改 GoogleIcon 組件
const GoogleIcon = () => (
  <svg viewBox="0 0 24 24" width="20" height="20">
    <path
      fill="#4285F4"
      d="M23.745 12.27c0-.79-.07-1.54-.19-2.27h-11.3v4.51h6.47c-.29 1.48-1.14 2.73-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82z"
    />
    <path
      fill="#34A853"
      d="M12.255 24c3.24 0 5.95-1.08 7.93-2.91l-3.86-3c-1.08.72-2.45 1.16-4.07 1.16-3.13 0-5.78-2.11-6.73-4.96h-3.98v3.09C3.515 21.3 7.565 24 12.255 24z"
    />
    <path
      fill="#FBBC05"
      d="M5.525 14.29c-.25-.72-.38-1.49-.38-2.29s.14-1.57.38-2.29V6.62h-3.98a11.86 11.86 0 000 10.76l3.98-3.09z"
    />
    <path
      fill="#EA4335"
      d="M12.255 4.75c1.77 0 3.35.61 4.6 1.8l3.42-3.42C18.205 1.19 15.495 0 12.255 0c-4.69 0-8.74 2.7-10.71 6.62l3.98 3.09c.95-2.85 3.6-4.96 6.73-4.96z"
    />
  </svg>
);

// 組件實現
interface SidebarProps {
  onNewChat: () => void;
  currentSession: string | null;
  setCurrentSession: (id: string | null) => void;
  isCollapsed: boolean;
  setIsCollapsed: (collapsed: boolean) => void;
  sessions: ChatSession[];
  onDeleteSession?: (sessionId: string) => void;
  onStarChange?: (sessionId: string, isStarred: boolean) => void;
  onSessionsReorder?: (sessions: ChatSession[]) => void;
  onRefreshTitle?: (sessionId: string) => void;
  user: User | null;
  onLogin: (user: User) => void;
  onLogout: () => void;
}

// 修改 DraggableSessionItem 組件
const SortableSessionItem: React.FC<{
  session: ChatSession;
  index: number;
  isSelected: boolean;
  isCollapsed: boolean;
  onSelect: () => void;
  onStar: (e: React.MouseEvent) => void;
  onDelete: (e: React.MouseEvent) => void;
}> = ({ session, isSelected, isCollapsed, onSelect, onStar, onDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: session.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <SessionItem
      ref={setNodeRef}
      style={style}
      selected={isSelected}
      isCollapsed={isCollapsed}
      onClick={onSelect}
    >
      <DragHandle {...attributes} {...listeners}>
        <DragHandleIcon />
      </DragHandle>
      <SessionTitle isCollapsed={isCollapsed}>
        {session.title || '新對話'}
      </SessionTitle>
      <SessionActions>
        <StarButton
          isStarred={session.isStarred}
          onClick={onStar}
          title={session.isStarred ? '取消星標' : '加入星標'}
          data-starred={session.isStarred}
        >
          <StarIcon filled={session.isStarred} />
        </StarButton>
        <ActionButton
          onClick={onDelete}
          title="刪除對話"
        >
          <DeleteIcon />
        </ActionButton>
      </SessionActions>
    </SessionItem>
  );
};

// 組件實現
const Sidebar: React.FC<SidebarProps> = ({
  onNewChat,
  currentSession,
  setCurrentSession,
  isCollapsed,
  setIsCollapsed,
  sessions,
  onDeleteSession,
  onStarChange,
  onSessionsReorder,
  onRefreshTitle,
  user,
  onLogin,
  onLogout,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showStarredOnly, setShowStarredOnly] = useState(false);

  const handleNewChat = () => {
    onNewChat();
  };

  const toggleStar = (sessionId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    const session = StorageService.getSession(sessionId);
    if (session) {
      const newStarredState = !session.isStarred;
      const updatedSession = {
        ...session,
        isStarred: newStarredState,
        updatedAt: Date.now()
      };
      
      // 保存到本地存儲
      StorageService.saveChatSession(updatedSession);
      
      // 通知父組件更新 sessions
      if (onStarChange) {
        onStarChange(sessionId, newStarredState);
      }
    }
  };

  const handleDelete = (sessionId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (onDeleteSession) {
      onDeleteSession(sessionId);
    }
  };

  const filteredSessions = sessions
    .filter(session => 
      (!showStarredOnly || session.isStarred) &&
      (session.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
       session.messages.some((msg: Message) => 
         msg.content.toLowerCase().includes(searchTerm.toLowerCase())
       ))
    );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = filteredSessions.findIndex(session => session.id === active.id);
    const newIndex = filteredSessions.findIndex(session => session.id === over.id);
    
    const reorderedSessions = arrayMove([...sessions], oldIndex, newIndex);
    
    if (onSessionsReorder) {
      onSessionsReorder(reorderedSessions);
    }
  };

  const handleRefreshTitle = (sessionId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (onRefreshTitle) {
      onRefreshTitle(sessionId);
    }
  };

  // 處理 Google 登入
  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      onLogin(result.user as User);
    } catch (error) {
      console.error('Google 登入失敗:', error);
    }
  };

  return (
    <>
      <SidebarContainer isCollapsed={isCollapsed}>
        {!isCollapsed && (
          <>
            <NewChatContainer>
              <CollapseButton 
                onClick={() => setIsCollapsed(!isCollapsed)}
                isCollapsed={isCollapsed}
              >
                <CollapseIcon isCollapsed={isCollapsed} />
              </CollapseButton>
              <NewChatButton 
                onClick={handleNewChat} 
                isCollapsed={isCollapsed}
              >
                <PlusIcon />
              </NewChatButton>
            </NewChatContainer>
            
            <SearchContainer isCollapsed={isCollapsed}>
              <SearchInput
                type="text"
                placeholder="搜尋對話..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <StarFilterButton 
                onClick={() => setShowStarredOnly(!showStarredOnly)}
                isActive={showStarredOnly}
              >
                ⭐
              </StarFilterButton>
            </SearchContainer>
            
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={filteredSessions.map(s => s.id)}
                strategy={verticalListSortingStrategy}
              >
                <SessionList>
                  {filteredSessions.map((session, index) => (
                    <SortableSessionItem
                      key={session.id}
                      session={session}
                      index={index}
                      isSelected={currentSession === session.id}
                      isCollapsed={isCollapsed}
                      onSelect={() => setCurrentSession(session.id)}
                      onStar={(e) => toggleStar(session.id, e)}
                      onDelete={(e) => handleDelete(session.id, e)}
                    />
                  ))}
                </SessionList>
              </SortableContext>
            </DndContext>

            {/* 在版權聲明前添加登入按鈕 */}
            <div style={{ marginTop: 'auto', padding: '1rem' }}>
              {!user ? (
                <GoogleLoginButton onClick={handleGoogleLogin}>
                  <GoogleIcon />
                  使用 Google 登入
                </GoogleLoginButton>
              ) : (
                <UserInfo>
                  <UserAvatar src={user.photoURL || undefined} alt={user.displayName || '使用者'} />
                  <UserName>{user.displayName}</UserName>
                  <LogoutButton onClick={onLogout}>
                    登出
                  </LogoutButton>
                </UserInfo>
              )}
            </div>

            <Copyright>
              <CompanyLogo>
                <LogoImage 
                  src="/images/logo.png" 
                  alt="Stima Tech Logo"
                />
                Stima Tech
              </CompanyLogo>
              © {new Date().getFullYear()} All Rights Reserved.
            </Copyright>
          </>
        )}
      </SidebarContainer>

      {isCollapsed && (
        <FloatingButtonsContainer isCollapsed={isCollapsed}>
          <NewChatButton 
            onClick={handleNewChat} 
            isCollapsed={isCollapsed}
            floating
          >
            <PlusIcon />
          </NewChatButton>
          <CollapseButton 
            onClick={() => setIsCollapsed(!isCollapsed)}
            isCollapsed={isCollapsed}
          >
            <CollapseIcon isCollapsed={isCollapsed} />
          </CollapseButton>
        </FloatingButtonsContainer>
      )}
    </>
  );
};

// 添加使用者資訊相關的樣式
const UserInfo = styled.div`
  padding: 0.75rem;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 0.5rem 0;
`;

const UserAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  background: ${props => props.theme.background.default};
`;

const UserName = styled.span`
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LogoutButton = styled.button`
  padding: 0.25rem 0.75rem;
  background: none;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  color: ${props => props.theme.text.secondary};
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.status.error}15;
    border-color: ${props => props.theme.status.error};
    color: ${props => props.theme.status.error};
  }
`;

export default Sidebar; 