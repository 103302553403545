import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { SUPPORTED_PROVIDERS } from '../types/models';
import type { Settings as BaseSettingsType, Model } from '../types';
import { GoogleLogin } from './GoogleLogin';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../config/firebase';
import type { User } from '../types/auth';
import { generateSessionToken, clearSessionToken } from '../utils/auth';

// 臨時擴展 Settings 類型（如果需要的話）
type SettingsType = BaseSettingsType & {
  temperature?: number;
  responseLanguage?: 'zh-TW' | 'en';
  contextWindowSize?: number;
  compressionType?: 'fixed' | 'summary';
  summaryTriggerRounds?: number;
  compressionModel?: string;
  autoGenerateTitle?: boolean;
  // 添加新的參數
  top_k?: number;
  top_p?: number;
  keepFullContextRounds?: number; // 添加新的類型
};

interface SettingsProps {
  settings: SettingsType;
  setSettings: (settings: SettingsType) => void;
  isDarkMode: boolean;
  setIsDarkMode: (isDarkMode: boolean) => void;
  onClose: () => void;
}

const SettingsContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  background: ${props => props.theme.background.paper};
  box-shadow: ${props => props.theme.shadows.lg};
  padding: 2rem;
  overflow-y: auto;
  z-index: 1100;
  transition: all 0.3s ease;
  transform: translateX(${props => props.className === 'closing' ? '100%' : '0'});
  will-change: transform;
  
  &.opening {
    animation: slideIn 0.3s ease;
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1.5rem;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 1050;
  transition: all 0.3s ease;
  backdrop-filter: blur(0px);
  will-change: backdrop-filter, background-color, opacity;

  &:not(.closing) {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
  }

  &.closing {
    background: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: ${props => props.theme.text.secondary};
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    color: ${props => props.theme.text.primary};
  }
`;

const PrePromptTextarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 0.75rem 1rem;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  background: ${props => props.theme.background.default};
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }

  &::placeholder {
    color: ${props => props.theme.text.disabled};
  }
`;

const HelpText = styled.small`
  color: ${props => props.theme.text.secondary};
  display: block;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  user-select: none;
`;

const SettingsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
`;

const Title = styled.h2`
  margin: 0;
  color: ${props => props.theme.text.primary};
  font-size: 1.5rem;
  font-weight: 600;
  user-select: none;
`;

const Subtitle = styled.h3`
  margin: 0;
  color: ${props => props.theme.text.secondary};
  font-size: 0.875rem;
  font-weight: 400;
  user-select: none;
`;

// 首先添加預設提示詞的類型定
interface PresetPrompt {
  id: string;
  title: string;  // 角色名稱
  content: string;  // 角色描述
  category: string;
  isCustom?: boolean;
}

// 添加預設提示詞數據
const PRESET_PROMPTS: PresetPrompt[] = [
  // 行銷類專家
  {
    id: 'advertising',
    title: '廣告文案專家',
    content: '我希望你擔任廣告商的角色。你將策劃一場活動，以推廣你所選擇的產品或服務。你需要選定目標受眾，擬定關鍵訊息和口號，選擇宣傳的媒體管道，並決定達成目標所需的其他活動。',
    category: '行銷'
  },
  {
    id: 'social-media',
    title: '社群媒體經理',
    content: '請以社群媒體經理的身份協助我。你需要規劃社群內容、設計貼文、建議最佳發文時間，並提供提升互動率的策略。請用活潑但專業的語氣回答，並考慮不同社群平台的特性給出建議。',
    category: '行銷'
  },
  {
    id: 'content-strategy',
    title: '內容策略專家',
    content: '你是一位內容行銷策略專家。請協助規劃內容行銷策略，包括：目標受眾分析、內容主題發想、內容形式建議發布頻率規劃，以及成效評估指標。請提供具體可行的建議。',
    category: '行銷'
  },
  // 新增行銷類角色
  {
    id: 'seo-specialist',
    title: 'SEO 優化專家',
    content: '你是一位資深的 SEO 優化專家，精通搜尋引擎優化策略。請提供 SEO 相關建議，包括：關鍵字研究、網站架構優化、內容優化、外部連結建立策略等��注重可持續性和白帽技術。',
    category: '行銷'
  },
  {
    id: 'email-marketing',
    title: '電子郵件行銷專家',
    content: '你是一位專業的電子郵件行銷專家，擅長設計和優化電子郵件行銷活動。請提供建議，包括：郵件內容撰寫、受眾分群、A/B 測試、自動化流程設計、轉換率優化等。',
    category: '行銷'
  },

  // 教育培訓類
  {
    id: 'curriculum-designer',
    title: '課程設計師',
    content: '你是一位專業的課程設計師，擅長設計有效的學習體驗。請協助規劃課程內容，包括：學習目標設定、課程大綱規劃、教學活動設計、評量方式建議等。注重學習成效和學習者體驗。',
    category: '教育培訓'
  },
  {
    id: 'education-consultant',
    title: '教育顧問',
    content: '你是一位經驗豐富的教育顧問，專注於教育策略和學習方法指導。請提供教育相關建議，包括：學習計畫制定、學習方法指導、升學規劃、能力培養建議等。',
    category: '教育培訓'
  },
  // 新增教育培訓類角色
  {
    id: 'stem-educator',
    title: 'STEM 教育專家',
    content: '你是一位專業的 STEM 教育專家，擅長設計跨領域的科學、技術、工程和數學教學活動。請提供 STEM 教育建議，包括：專案式學習設計、實驗活動規劃、評量方式建議等。',
    category: '教育培訓'
  },
  {
    id: 'special-education',
    title: '特殊教育專家',
    content: '你是一位經驗豐富的特殊教育專家，專注於特殊需求學生的學習輔導。請提供特教相關建議，包括：個別化教育計畫、學習策略調整、行為輔導、家長溝通等。',
    category: '教育培訓'
  },

  // 商業諮詢類
  {
    id: 'business-analyst',
    title: '商業分析師',
    content: '你是一位專業的商業分析師，擅長業務流程分析和優化。請協助分析業務問題，提供解決方案，�����括：流程優化建議、效能評估、風險分析、成本效益分析等。',
    category: '商業諮詢'
  },
  {
    id: 'investment-advisor',
    title: '投資顧問',
    content: '你是一位專業的投資顧問，精通各類投資工具和市場分析。請提供投資建議，包括：資產配置策略、風險評估、投資組合規劃、市場趨勢分析等。注重風險管理和長期收益。',
    category: '商業諮詢'
  },
  // 新增商業諮詢類角色
  {
    id: 'hr-consultant',
    title: '人力資源顧問',
    content: '你是一位資深的人力資源顧問，專注於組織發展和人才管理。���提���人資相關建議，包括：組織架構設計、招募策略、績效管理制度、員工發展計畫等。',
    category: '商業諮詢'
  },
  {
    id: 'change-management',
    title: '變革管理顧問',
    content: '你是一位專業的變革管理顧問，擅長協助組織進行轉型和創新。請提供變革管理建議，包括：變革策略規劃、利害關係人管理、溝通計畫、阻力處理等。',
    category: '商業諮詢'
  },

  // 創意設計類
  {
    id: 'ux-designer',
    title: 'UX 設計師',
    content: '你是一位專業的 UI 設計師，精通用戶界面設計原則和最新設計趨勢。請提供 UI 設計相關建議，包括：視覺層級、配色方案、排版布局、組件設計等。注重美觀性、一致性和可用性。',
    category: '創意設計'
  },
  // 新增創意設計類角色
  {
    id: 'motion-designer',
    title: '動態設計師',
    content: '你是一位專業的動態設計師，精通動畫和互動設計。請提供動態設計建議，包括：動畫腳本、轉場效果、互動設計、時間軸控制等。注重流暢性和使用者體驗。',
    category: '創意設計'
  },
  {
    id: 'brand-designer',
    title: '品牌設計師',
    content: '你是一位資深的品牌設計師，專注於品牌視覺識別系統設計。請提供品牌設計建議，包括：品牌標誌、色彩系統、字體規範、應用設計等。注重品牌一致性和識別度。',
    category: '創意設計'
  },

  // 數據分析類
  {
    id: 'data-analyst',
    title: '數據分析師',
    content: '你是一位專業的數據分析師，擅長數據處理和分析洞察。請提供數據分析建議，包括：數據清理、統計分析、視覺化呈現、報告撰寫等。注重數據準確性和洞察價值。',
    category: '數據分析'
  },
  // 新增數據分析類角色
  {
    id: 'market-researcher',
    title: '市場研究分析師',
    content: '你是一位專業的市場研究分析師，擅長市場趨勢分析和消費者研究。請提供市場研究建議，包括：研究方法設計、數據收集、深度訪談、競品分析等。',
    category: '數據分析'
  },
  {
    id: 'predictive-analyst',
    title: '預測分析專家',
    content: '你是一位資深的預測分析專家，精通預測模型建立和時間序列分析。請提供預測分析建議，包括：模型選擇、特徵工程、模型評估、結果解釋等。',
    category: '數據分析'
  },

  // 新增系統運維類角色
  {
    id: 'kubernetes-expert',
    title: 'Kubernetes 專家',
    content: '你是一位資深的 Kubernetes 專家，精通容器編排和叢集管理。請提供 K8s 相關建議，包括：架構設計、部署策略、效能優化、故障排除等。注重可靠性和擴展性。',
    category: '系統運維'
  },
  {
    id: 'monitoring-expert',
    title: '監控系統專家',
    content: '你是一位專業的監控系統專家，精通各類監控工具和最佳實踐。請提供監控相關建議，包括：監控架構設計、告警策略、儀表板設計、效能指標選擇等。',
    category: '系統運維'
  },
  {
    id: 'database-admin',
    title: '資料庫管理員',
    content: '你是一位資深的資料庫管理員，精通各類資料庫的維護和優化。請提供資料庫相關建議，包括：效能調校、備份策略、高可用設計、資料安全等。',
    category: '系統運維'
  },

  // 新增雲端平台類角色
  {
    id: 'serverless-expert',
    title: 'Serverless 架構專家',
    content: '你是一位專業的 Serverless 架構專家，精通無伺服器架構設計。請提供 Serverless 相關建議，包括：架構設計、成本優化、效能調校、最佳實踐等。',
    category: '雲端平台'
  },
  {
    id: 'cloud-native',
    title: '雲原生專家',
    content: '你是一位資深的雲原生專家，精通雲原生應用開發和部署。請提供雲原生相關建議，包括：微服務設計、容器化策略、服務網格、CI/CD 流程等。',
    category: '雲端平台'
  },
  {
    id: 'multicloud-architect',
    title: '多雲架構師',
    content: '你是一位專業的多雲架構師，精通跨雲平台的架構設計。請提供多雲架構建議，包括：雲平台選擇、混合雲策略、資料同步、災備設計等。',
    category: '雲端平台'
  },

  // 新增程式開發類角色
  {
    id: 'security-developer',
    title: '安全開發專家',
    content: '你是一位資深的安全開發專家，精通安全編碼和漏洞防護。請提供安全開發建議，包括：程式碼審查、安全測試、漏洞修復、安全框架使用等。',
    category: '程式開發'
  },
  {
    id: 'performance-expert',
    title: '效能優化專家',
    content: '你是一位專業的效能優化專家，精通系統效能分析和優化。請提供效能優化建議，包括：效能瓶頸分析、記憶體優化、CPU 優化、網路優化等。',
    category: '程式開發'
  },
  {
    id: 'api-designer',
    title: 'API 設計專家',
    content: '你是一位資深的 API 設計專家，精通 RESTful API 和 GraphQL 設計。請提供 API 設計建議，包括：接口設計、版本控制、文件撰寫、安全規範等。',
    category: '程式開發'
  },
  {
    id: 'testing-expert',
    title: '測試專家',
    content: '你是一位專業的測試專家，精通各類測試方法和工具。請提供測試相關建議，包括：測試策略、自動化測試、性能測試、安全測試等。',
    category: '程式開發'
  },
  {
    id: 'devops-culture',
    title: 'DevOps 文化教練',
    content: '你是一位資深的 DevOps 文化教練，專注於團隊協作和持續改進。請提供 DevOps 相關建議，包括：團隊協作、流程優化、工具選擇、度量指標等。',
    category: '程式開發'
  }
];

// 添加預設提示詞列表的樣式組件
const PresetPromptsButton = styled.button`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    border-color: ${props => props.theme.primary.main};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const PresetPromptsPopup = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 900px;  // 增加最大寬度
  max-height: 80vh;
  background: ${props => props.theme.background.paper};
  border-radius: 12px;
  box-shadow: ${props => props.theme.shadows.lg};
  z-index: 1300;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  animation: fadeIn 0.2s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.98);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;

const PopupHeader = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${props => props.theme.border.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupTitle = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  color: ${props => props.theme.text.primary};
  user-select: none;
`;

const PopupContent = styled.div`
  display: flex;
  height: calc(80vh - 60px);
  overflow: hidden;
`;

const CategorySidebar = styled.div`
  width: 200px;
  min-width: 200px;
  border-right: 1px solid ${props => props.theme.border.main};
  overflow-y: auto;
  padding: 1rem;
  background: ${props => props.theme.background.default};
`;

const CategoryTabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CategoryTab = styled.button<{ active: boolean }>`
  padding: 0.75rem 1rem;
  border: 1px solid ${props => props.active ? props.theme.primary.main : props.theme.border.main};
  border-radius: 8px;
  background: ${props => props.active ? props.theme.primary.main + '20' : props.theme.background.paper};
  color: ${props => props.active ? props.theme.primary.main : props.theme.text.secondary};
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  text-align: left;
  user-select: none;

  &:hover {
    border-color: ${props => props.theme.primary.main};
    background: ${props => props.theme.primary.main + '10'};
  }

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PromptList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PromptItem = styled.div`
  padding: 1rem;
  background: ${props => props.theme.background.default};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const PromptTitle = styled.div`
  font-weight: 600;
  color: ${props => props.theme.text.primary};
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`;

const PromptContent = styled.div`
  color: ${props => props.theme.text.secondary};
  font-size: 0.875rem;
  line-height: 1.5;
`;

const CopyButton = styled.button`
  padding: 0.25rem 0.75rem;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  color: ${props => props.theme.text.secondary};
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    color: ${props => props.theme.primary.main};
  }

  &.copied {
    background: ${props => props.theme.primary.main};
    color: white;
    border-color: ${props => props.theme.primary.main};
  }
`;

// 添加分類標籤的類型
type Category = {
  id: string;
  name: string;
  icon: React.ReactNode;
  category?: string;
};

// 定義分類
const CATEGORIES: Category[] = [
  {
    id: 'all',
    name: '全部',
    icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M3 12h18M3 6h18M3 18h18"/>
    </svg>
  },
  {
    id: 'marketing',
    category: '行銷',
    name: '行銷與廣告',
    icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/>
    </svg>
  },
  {
    id: 'education',
    category: '教育培訓',
    name: '教育培訓',
    icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/>
      <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/>
    </svg>
  },
  {
    id: 'business',
    category: '商業諮詢',
    name: '商業諮詢',
    icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M22 12h-4l-3 9L9 3l-3 9H2"/>
    </svg>
  },
  {
    id: 'creative',
    category: '創意設計',
    name: '創意設計',
    icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M12 19l7-7 3 3-7 7-3-3z"/>
      <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"/>
      <path d="M2 2l7.586 7.586"/>
    </svg>
  },
  {
    id: 'data',
    category: '數據分析',
    name: '數據分析',
    icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M21 21H3V3"/>
      <path d="M21 9L13 3L9 9L3 6"/>
    </svg>
  },
  {
    id: 'system-ops',
    category: '系統運維',
    name: '系統運維',
    icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/>
    </svg>
  },
  {
    id: 'cloud-platform',
    category: '雲端平台',
    name: '雲端平台',
    icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M3 12h18M3 6h18M3 18h18"/>
    </svg>
  },
  {
    id: 'development',
    category: '程式開發',
    name: '程式開發',
    icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M16 18l6-6-6-6M8 6l-6 6 6 6"/>
    </svg>
  }
];

// 添加新的按鈕和表單樣式
const AddPromptButton = styled.button`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    border-color: ${props => props.theme.primary.main};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const AddPromptForm = styled.form`
  padding: 1rem;
  background: ${props => props.theme.background.default};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  font-weight: 500;
  user-select: none;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 6px;
  background: ${props => props.theme.background.paper};
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 6px;
  background: ${props => props.theme.background.paper};
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 6px;
  background: ${props => props.theme.background.paper};
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  min-height: 100px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }
`;

const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
`;

// 添��� SecondaryButton 樣式組件
const SecondaryButton = styled.button`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    border-color: ${props => props.theme.primary.main};
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

// 首先定義所有樣式組件
const SettingGroup = styled.div`
  margin-bottom: 2rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.label<{ marginTop?: string }>`
  display: block;
  margin-bottom: 0.5rem;
  margin-top: ${props => props.marginTop || '0'};  // 添加可選的上邊距
  color: ${props => props.theme.text.secondary};
  font-size: 0.875rem;
  font-weight: 500;
  user-select: none;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  background: ${props => props.theme.background.default};
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  transition: all 0.2s ease-in-out;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }

  &::placeholder {
    color: ${props => props.theme.text.disabled};
  }

  &[type="password"] {
    letter-spacing: 0.2em;
    font-family: monospace;
  }
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.text.secondary};
  font-size: 0.875rem;
  font-weight: 500;
`;

const InputAddon = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.text.secondary};
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const InputIcon = styled.span`
  color: ${props => props.theme.text.disabled};
  font-size: 1rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  background: ${props => props.theme.background.default};
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }
`;

const ModelSelectContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const AddModelButton = styled.button`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.primary.main};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  
  &:hover {
    background: ${props => props.theme.primary.dark};
  }
`;

const CustomModelInput = styled.div`
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.primary.main};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  
  &:hover {
    background: ${props => props.theme.primary.dark};
  }
`;

// 添加缺失的樣式組件
const PromptActions = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const DeleteButton = styled.button`
  padding: 0.25rem 0.75rem;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  color: ${props => props.theme.error?.main || '#ff4444'};
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => (props.theme.error?.main || '#ff4444') + '10'};
    border-color: ${props => props.theme.error?.main || '#ff4444'};
  }
`;

const TemperatureSlider = styled.input`
  width: 100%;
  margin: 1rem 0;
  -webkit-appearance: none;
  height: 6px;
  border-radius: 3px;
  background: ${props => `linear-gradient(
    to right,
    ${props.theme.primary.main} 0%,
    ${props.theme.primary.main} calc((var(--value) - var(--min)) / (var(--max) - var(--min)) * 100%),
    ${props.theme.background.paper} calc((var(--value) - var(--min)) / (var(--max) - var(--min)) * 100%)
  )`};
  outline: none;
  opacity: 0.9;
  transition: all 0.2s ease;
  cursor: pointer;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  
  &:hover {
    opacity: 1;
  }
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${props => props.theme.primary.main};
    cursor: pointer;
    border: 3px solid #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    margin-top: -8px;
    
    &:hover {
      transform: scale(1.15);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    }
    
    &:active {
      transform: scale(0.95);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &::-moz-range-thumb {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${props => props.theme.primary.main};
    cursor: pointer;
    border: 3px solid #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    
    &:hover {
      transform: scale(1.15);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    }
    
    &:active {
      transform: scale(0.95);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.2s ease;
  }

  &::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: ${props => props.theme.background.paper};
    border-radius: 3px;
    transition: all 0.2s ease;
  }

  &:focus {
    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 2px ${props => props.theme.primary.main}30;
    }
    
    &::-moz-range-thumb {
      box-shadow: 0 0 0 2px ${props => props.theme.primary.main}30;
    }
  }
`;

const TemperatureValue = styled.span`
  color: ${props => props.theme.primary.main};
  font-size: 1rem;
  font-weight: 500;
  margin-left: 1rem;
  min-width: 2.5rem;
  text-align: center;
  padding: 0.25rem 0.5rem;
  background: ${props => props.theme.primary.main}10;
  border-radius: 4px;
  transition: all 0.2s ease;
`;

// 修正 onChange 事件的類型
type TemperatureChangeEvent = React.ChangeEvent<HTMLInputElement>;

// 添加開關按鈕的樣式
const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin-top: 0.5rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.border.main};
    transition: .4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input:checked + span {
    background-color: ${props => props.theme.primary.main};
  }

  input:checked + span:before {
    transform: translateX(24px);
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
`;

const SwitchLabel = styled.span`
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
`;

// ���加新的滑塊樣式組件
const ParameterSlider = styled(TemperatureSlider)`
  // 繼承 TemperatureSlider 的樣式
`;

// 添加新的值顯示式組件
const ParameterValue = styled(TemperatureValue)`
  // 繼承 TemperatureValue 的樣式
`;

// 添加 GroupTitle 樣式組件定義
const GroupTitle = styled.h3`
  color: ${props => props.theme.text.primary};
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${props => props.theme.border.main};
  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &::before {
    content: '';
    width: 4px;
    height: 1em;
    background: ${props => props.theme.primary.main};
    border-radius: 2px;
  }
`;

// 添加 ApplyButton 樣式組件
const ApplyButton = styled.button`
  padding: 0.25rem 0.75rem;
  background: ${props => props.theme.primary.main}10;
  border: 1px solid ${props => props.theme.primary.main}40;
  border-radius: 4px;
  color: ${props => props.theme.primary.main};
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.primary.main}20;
    border-color: ${props => props.theme.primary.main};
  }

  &.applied {
    background: ${props => props.theme.primary.main};
    color: white;
    border-color: ${props => props.theme.primary.main};
  }
`;

const Settings: React.FC<SettingsProps> = ({
  settings,
  setSettings,
  isDarkMode,
  setIsDarkMode,
  onClose
}) => {
  const [selectedProvider, setSelectedProvider] = useState(() => {
    const provider = SUPPORTED_PROVIDERS.find(p => 
      p.name.toLowerCase() === settings.provider
    );
    return provider ? provider.name : SUPPORTED_PROVIDERS[0].name;
  });
  const [customModel, setCustomModel] = useState('');
  const [showCustomModel, setShowCustomModel] = useState(false);
  const [showPresetPrompts, setShowPresetPrompts] = useState(false);
  const [copiedPromptId, setCopiedPromptId] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showAddPrompt, setShowAddPrompt] = useState(false);
  const [newPrompt, setNewPrompt] = useState({
    title: '',
    content: '',
    category: 'marketing'
  });
  const [isClosing, setIsClosing] = useState(false);
  const [promptsVersion, setPromptsVersion] = useState(0);
  const [appliedPromptId, setAppliedPromptId] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (!settings.compressionType) {
      setSettings({
        ...settings,
        compressionType: 'fixed',
        contextWindowSize: 10,
        summaryTriggerRounds: 5,
        compressionModel: 'gpt-4o-mini'
      });
    }
  }, []);

  const handleProviderChange = (providerName: string) => {
    setSelectedProvider(providerName);
    const provider = SUPPORTED_PROVIDERS.find(p => p.name === providerName);
    if (provider && provider.models.length > 0) {
      setSettings({
        ...settings,
        currentModel: provider.models[0].id,
        provider: providerName.toLowerCase()
      });
    }
  };

  const handleAddCustomModel = () => {
    if (!customModel.trim()) return;
    
    const provider = SUPPORTED_PROVIDERS.find(p => p.name === selectedProvider);
    if (provider) {
      const newModel = {
        id: customModel,
        name: customModel,
        contextWindow: 0,  // 默認值
        provider: provider.name.toLowerCase()
      };
      
      // 更新本地存儲中的自定義模型
      const customModels = JSON.parse(localStorage.getItem('custom_models') || '[]');
      customModels.push(newModel);
      localStorage.setItem('custom_models', JSON.stringify(customModels));
      
      setCustomModel('');
      setShowCustomModel(false);
      setSettings({
        ...settings,
        currentModel: newModel.id
      });
    }
  };

  const getAvailableModels = () => {
    const provider = SUPPORTED_PROVIDERS.find(p => p.name === selectedProvider);
    if (!provider) return [];
    
    // 使用 Set 來存儲模型 ID，防止重複
    const modelIds = new Set<string>();
    const availableModels: Model[] = [];

    // 首先添加標準模型
    provider.models.forEach(model => {
      if (!modelIds.has(model.id)) {
        modelIds.add(model.id);
        availableModels.push(model);
      }
    });

    // 然後添加自定義模型（如果不重複的話）
    const customModels = JSON.parse(localStorage.getItem('custom_models') || '[]')
      .filter((model: Model) => 
        model.provider.toLowerCase() === provider.name.toLowerCase() &&
        !modelIds.has(model.id)  // 只添加未出現過的模型
      );
    
    return [...availableModels, ...customModels];
  };

  const handleCopyPrompt = (prompt: PresetPrompt) => {
    navigator.clipboard.writeText(prompt.content);
    setCopiedPromptId(prompt.id);
    setTimeout(() => setCopiedPromptId(null), 2000);
  };

  // 處理添加新提示詞
  const handleAddPrompt = (e: React.FormEvent) => {
    e.preventDefault();
    
    // 找到對應的類別
    const selectedCat = CATEGORIES.find(cat => cat.id === newPrompt.category);
    if (!selectedCat) return;

    // 生成唯一 ID
    const newId = `custom-${Date.now()}`;
    
    // 創建新的提示詞，使用類別的 category 屬性或 id
    const customPrompt: PresetPrompt = {
      id: newId,
      title: newPrompt.title,
      content: newPrompt.content,
      category: selectedCat.category || selectedCat.id
    };

    // 從本地存儲獲取現有的自定義提示詞
    const customPrompts = JSON.parse(localStorage.getItem('custom_prompts') || '[]');
    
    // 添加新的提示詞
    localStorage.setItem('custom_prompts', JSON.stringify([...customPrompts, customPrompt]));

    // 重置表單
    setNewPrompt({
      title: '',
      content: '',
      category: 'marketing'
    });
    setShowAddPrompt(false);
    
    // 增加版本號來強制重新渲染
    setPromptsVersion(prev => prev + 1);

    // 直接應用新的提示詞
    setSettings({
      ...settings,
      prePrompt: customPrompt.content
    });
  };

  // 修改 getFilteredPrompts 函數
  const getFilteredPrompts = useCallback(() => {
    const customPrompts = JSON.parse(localStorage.getItem('custom_prompts') || '[]') as PresetPrompt[];
    const allPrompts = [
      ...PRESET_PROMPTS.map((prompt: PresetPrompt) => ({ ...prompt, isCustom: false })),
      ...customPrompts.map((prompt: PresetPrompt) => ({ ...prompt, isCustom: true }))
    ];

    if (selectedCategory === 'all') {
      return allPrompts;
    }

    // 找到選中的類別配置
    const selectedCat = CATEGORIES.find(cat => cat.id === selectedCategory);
    if (!selectedCat) return allPrompts;

    return allPrompts.filter(prompt => {
      // 如果類別有定義 category 屬性，使用它來過濾
      if (selectedCat.category) {
        return prompt.category === selectedCat.category;
      }
      
      // 否則使用 id 作為類別
      return prompt.category === selectedCat.id;
    });
  }, [selectedCategory, promptsVersion]);

  // 在渲染列表的部分使用 useMemo 來優化性能
  const filteredPrompts = useMemo(() => getFilteredPrompts(), [getFilteredPrompts]);

  // 添�����刪除提示詞的處理函數
  const handleDeletePrompt = (promptId: string) => {
    // 從本地存儲中獲取自定義提示詞
    const customPrompts = JSON.parse(localStorage.getItem('custom_prompts') || '[]');
    // 過濾掉要刪除的提示詞
    const updatedPrompts = customPrompts.filter((prompt: PresetPrompt) => prompt.id !== promptId);
    // 更新本地存儲
    localStorage.setItem('custom_prompts', JSON.stringify(updatedPrompts));
    
    // 如果當前正在使用這個提示詞，清空設置
    if (settings.prePrompt === customPrompts.find((p: PresetPrompt) => p.id === promptId)?.content) {
      setSettings({
        ...settings,
        prePrompt: ''
      });
    }
    
    // 增加版本號來強制重新渲染
    setPromptsVersion(prev => prev + 1);
  };

  const handleClose = () => {
    setIsClosing(true);
    // 移除遲疑的 setTimeout，直接在動畫結束時觸發 onClose
    requestAnimationFrame(() => {
      onClose();
    });
  };

  // 處理語言變更，但不改變示給使用者看的 prePrompt
  const handleLanguageChange = (newLanguage: 'zh-TW' | 'en') => {
    setSettings({
      ...settings,
      responseLanguage: newLanguage,
    });
  };

  // 處理使用者的 prePrompt 變更
  const handlePrePromptChange = (newPrePrompt: string) => {
    setSettings({
      ...settings,
      prePrompt: newPrePrompt
    });
  };

  // 添加處理套用的函數
  const handleApplyPrompt = (prompt: PresetPrompt) => {
    handlePrePromptChange(prompt.content);
    setAppliedPromptId(prompt.id);
    // 2秒後重置套用狀態
    setTimeout(() => setAppliedPromptId(null), 2000);
    // 關閉彈窗
    setShowPresetPrompts(false);
  };

  // 添加 Esc 鍵監聽器
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (showPresetPrompts) {
          setShowPresetPrompts(false);
        } else {
          handleClose();
        }
      }
    };

    // 添加事件監聽器
    document.addEventListener('keydown', handleEscKey);

    // 清理函數
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [showPresetPrompts]); // 依賴於 showPresetPrompts 的狀態

  // 處理 Google 登入
  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      // 登入成功
      const user = result.user as User;
      setUser(user);
      setIsLoggedIn(true);
      
      // 生成 session token
      await generateSessionToken(user);
      
      // 從 localStorage 讀取使用者設定
      const userSettings = localStorage.getItem(`settings_${user.uid}`);
      if (userSettings) {
        const settings = JSON.parse(userSettings);
        setSettings(settings);
      }
      
    } catch (error) {
      console.error('Google 登入失敗:', error);
    }
  };

  // 處理登出
  const handleLogout = async () => {
    try {
      await auth.signOut();
      clearSessionToken();
      setUser(null);
      setIsLoggedIn(false);
    } catch (error) {
      console.error('登出失敗:', error);
    }
  };

  // 監聽登入狀態
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user as User);
        setIsLoggedIn(true);
      } else {
        setUser(null);
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // 當設定變更時儲存到 localStorage
  useEffect(() => {
    if (user) {
      localStorage.setItem(`settings_${user.uid}`, JSON.stringify(settings));
    }
  }, [settings, user]);

  // 添加 Google 圖標組件
  const GoogleIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M21.35 11.1h-9.17v2.73h6.51c-.33 3.81-3.5 5.44-6.5 5.44C8.36 19.27 5 16.25 5 12c0-4.1 3.2-7.27 7.2-7.27 3.09 0 4.9 1.97 4.9 1.97L19 4.72S16.56 2 12.1 2C6.42 2 2.03 6.8 2.03 12c0 5.05 4.13 10 10.22 10 5.35 0 9.25-3.67 9.25-9.09 0-1.15-.15-1.81-.15-1.81z"
      />
    </svg>
  );

  // 修改 GoogleLoginButton 組件
  const GoogleLoginButton = styled.button`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 1.5rem;
    background: ${props => props.theme.background.paper};
    border: 1px solid ${props => props.theme.border.main};
    border-radius: 8px;
    color: ${props => props.theme.text.primary};
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: ${props => props.theme.secondary.hover};
      border-color: ${props => props.theme.primary.main};
      transform: translateY(-2px);
      box-shadow: ${props => props.theme.shadows.md};
    }

    &:active {
      transform: translateY(0);
    }

    svg {
      width: 24px;
      height: 24px;
      color: #4285f4;
    }
  `;

  return (
    <>
      <Overlay 
        className={isClosing ? 'closing' : ''} 
        onClick={handleClose} 
      />
      <SettingsContainer className={isClosing ? 'closing' : ''}>
        <CloseButton onClick={handleClose}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </CloseButton>
        
        <SettingsHeader>
          <LogoContainer>
            <LogoImage src="/images/logo.png" alt="Stima Tech Logo" />
            <Title>Stima Playground</Title>
            <Subtitle>設定</Subtitle>
          </LogoContainer>
        </SettingsHeader>

        {/* 模型設置 */}
        <SettingGroup>
          <GroupTitle>模型設置</GroupTitle>
          
          <Label>選擇模型提供商：</Label>
          <Select
            value={selectedProvider}
            onChange={(e) => handleProviderChange(e.target.value)}
          >
            {SUPPORTED_PROVIDERS.map(provider => (
              <option key={provider.name} value={provider.name}>
                {provider.name} ({provider.models.length} models)
              </option>
            ))}
          </Select>

          <Label>選擇模型：</Label>
          <ModelSelectContainer>
            <Select
              value={settings.currentModel}
              onChange={(e) => setSettings({...settings, currentModel: e.target.value})}
            >
              {getAvailableModels().map(model => (
                <option key={model.id} value={model.id}>
                  {model.name}
                </option>
              ))}
            </Select>
            {isLoggedIn && (
              <AddModelButton onClick={() => setShowCustomModel(!showCustomModel)}>
                {showCustomModel ? '取消' : '添加模型'}
              </AddModelButton>
            )}
          </ModelSelectContainer>

          {showCustomModel && isLoggedIn && (
            <CustomModelInput>
              <Input
                type="text"
                value={customModel}
                onChange={(e) => setCustomModel(e.target.value)}
                placeholder="輸入模型名"
              />
              <Button onClick={handleAddCustomModel}>確認添加</Button>
            </CustomModelInput>
          )}
        </SettingGroup>

        {/* 回應行為設置 */}
        <SettingGroup>
          <GroupTitle>回應行為設置</GroupTitle>
          
          <Label>回應溫度 (Temperature)</Label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TemperatureSlider
              type="range"
              min="0"
              max="2"
              step="0.1"
              value={settings.temperature || 0.7}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = parseFloat(e.target.value);
                e.target.style.setProperty('--value', value.toString());
                setSettings({
                  ...settings,
                  temperature: value
                });
              }}
              style={{
                '--value': settings.temperature || '0.7',
                '--min': '0',
                '--max': '2'
              } as React.CSSProperties}
            />
            <TemperatureValue>
              {settings.temperature || 0.7}
            </TemperatureValue>
          </div>
          <HelpText>
            較低的溫度產生更確定的回應，較高的溫度產生更創意的回應。建議值: 0.7
          </HelpText>

          <Label>Top-K 採樣</Label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ParameterSlider
              type="range"
              min="0"
              max="100"
              step="1"
              value={settings.top_k || 50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = parseInt(e.target.value);
                e.target.style.setProperty('--value', value.toString());
                setSettings({
                  ...settings,
                  top_k: value
                });
              }}
              style={{
                '--value': settings.top_k || '50',
                '--min': '0',
                '--max': '100'
              } as React.CSSProperties}
            />
            <ParameterValue>
              {settings.top_k || 50}
            </ParameterValue>
          </div>
          <HelpText>
            控制每次選詞時考慮的候選詞數量。建議值: 50
          </HelpText>

          <Label>Top-P (核心採樣)</Label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ParameterSlider
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={settings.top_p || 0.9}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = parseFloat(e.target.value);
                e.target.style.setProperty('--value', value.toString());
                setSettings({
                  ...settings,
                  top_p: value
                });
              }}
              style={{
                '--value': settings.top_p || '0.9',
                '--min': '0',
                '--max': '1'
              } as React.CSSProperties}
            />
            <ParameterValue>
              {settings.top_p || 0.9}
            </ParameterValue>
          </div>
          <HelpText>
            控制累積機率閾值的採樣範圍。建議值: 0.9
          </HelpText>
        </SettingGroup>

        {/* 對話管理設置 */}
        <SettingGroup>
          <GroupTitle>對話管理</GroupTitle>
          
          <Label>上下文管理設置</Label>
          <Select
            value={settings.compressionType || 'fixed'}
            onChange={(e) => setSettings({
              ...settings,
              compressionType: e.target.value as 'fixed' | 'summary'
            })}
          >
            <option value="fixed">固定窗口（保留最近 N 輪對話）</option>
            <option value="summary">智能摘要（自動壓縮歷史對話）</option>
          </Select>

          {/* 根據選擇的模式顯示不同的設置選項 */}
          {(settings.compressionType === 'fixed' || !settings.compressionType) && (
            <>
              <Label style={{ marginTop: '1rem' }}>保留對話輪數</Label>
              <Select
                value={settings.contextWindowSize || 10}
                onChange={(e) => setSettings({
                  ...settings,
                  contextWindowSize: parseInt(e.target.value)
                })}
              >
                <option value="5">保留最近 5 輪</option>
                <option value="10">保留最近 10 輪（推薦）</option>
                <option value="15">保留最近 15 輪</option>
                <option value="20">保留最近 20 輪</option>
              </Select>
              <HelpText>
                只保留最近的幾輪對話，可以降低 API 請求的負擔，並保持對話的流暢性。建議值：10 輪
              </HelpText>
            </>
          )}

          {settings.compressionType === 'summary' && (
            <>
              <Label style={{ marginTop: '1rem' }}>觸發壓縮的對話輪數</Label>
              <Select
                value={settings.summaryTriggerRounds || 5}
                onChange={(e) => setSettings({
                  ...settings,
                  summaryTriggerRounds: parseInt(e.target.value)
                })}
              >
                <option value="3">累積 3 輪後壓縮</option>
                <option value="5">累積 5 輪後壓縮（推薦）</option>
                <option value="8">累積 8 輪後壓縮</option>
                <option value="10">累積 10 輪後壓縮</option>
              </Select>
              <HelpText>
                當新對話累積到指定輪數時，系統會自動對早期對話進行智能壓縮，以保上下文的精簡和相關性。建議值：5 輪
              </HelpText>

              <Label style={{ marginTop: '1rem' }}>保留完整對話輪數</Label>
              <Select
                value={settings.keepFullContextRounds || 3}
                onChange={(e) => setSettings({
                  ...settings,
                  keepFullContextRounds: parseInt(e.target.value)
                })}
              >
                <option value="2">保留最近 2 輪完整對話</option>
                <option value="3">保留最近 3 輪完整對話（推薦）</option>
                <option value="5">保留最近 5 輪完整對話</option>
                <option value="8">保留最近 8 輪完整對話</option>
              </Select>
              <HelpText>
                設定要保留多少輪最近的完整對話內容，這些對話不會被壓縮。建議值：3 輪
              </HelpText>
            </>
          )}
          
          <Label style={{ marginTop: '1.5rem' }}>預設提示詞</Label>
          <PrePromptTextarea
            value={settings.prePrompt || ''}
            onChange={(e) => handlePrePromptChange(e.target.value)}
            placeholder="例如：你是一位專業的軟體工程師..."
          />
          <PresetPromptsButton onClick={() => setShowPresetPrompts(true)}>
            瀏覽專家角色列表
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
          </PresetPromptsButton>
        </SettingGroup>

        {/* 介面設置 */}
        <SettingGroup>
          <GroupTitle>介面設置</GroupTitle>
          
          <Label>回應語言</Label>
          <Select
            value={settings.responseLanguage || 'zh-TW'}
            onChange={(e) => handleLanguageChange(e.target.value as 'zh-TW' | 'en')}
          >
            {LANGUAGE_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>

          <Label style={{ marginTop: '1.5rem' }}>對話標題</Label>
          <SwitchContainer>
            <SwitchLabel>自動生成對話標題</SwitchLabel>
            <ToggleSwitch>
              <input
                type="checkbox"
                checked={settings.autoGenerateTitle !== false}
                onChange={(e) => setSettings({
                  ...settings,
                  autoGenerateTitle: e.target.checked
                })}
              />
              <span></span>
            </ToggleSwitch>
          </SwitchContainer>
        </SettingGroup>
      </SettingsContainer>

      {/* 專家角色列表彈窗 */}
      {showPresetPrompts && (
        <>
          <Overlay 
            className={isClosing ? 'closing' : ''} 
            onClick={() => setShowPresetPrompts(false)} 
            style={{ zIndex: 1200 }}
          />
          <PresetPromptsPopup isOpen={true}>
            <PopupHeader>
              <PopupTitle>專家角色列表</PopupTitle>
              <CloseButton onClick={() => setShowPresetPrompts(false)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </CloseButton>
            </PopupHeader>
            
            <PopupContent>
              <CategorySidebar>
                <CategoryTabs>
                  {CATEGORIES.map(category => (
                    <CategoryTab
                      key={category.id}
                      active={selectedCategory === category.id}
                      onClick={() => setSelectedCategory(category.id)}
                    >
                      {category.icon}
                      {category.name}
                    </CategoryTab>
                  ))}
                </CategoryTabs>
              </CategorySidebar>

              <ContentArea>
                {isLoggedIn && (
                  <AddPromptButton onClick={() => setShowAddPrompt(!showAddPrompt)}>
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <line x1="12" y1="5" x2="12" y2="19" />
                      <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                    新增自定義角色
                  </AddPromptButton>
                )}

                {showAddPrompt && isLoggedIn && (
                  <AddPromptForm onSubmit={handleAddPrompt}>
                    <FormGroup>
                      <FormLabel>角色名稱</FormLabel>
                      <FormInput
                        type="text"
                        value={newPrompt.title}
                        onChange={(e) => setNewPrompt({...newPrompt, title: e.target.value})}
                        placeholder="輸入角色名稱"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>專業領域</FormLabel>
                      <FormSelect
                        value={newPrompt.category}
                        onChange={(e) => setNewPrompt({...newPrompt, category: e.target.value})}
                        required
                      >
                        {CATEGORIES.filter(cat => cat.id !== 'all').map(cat => (
                          <option key={cat.id} value={cat.id}>
                            {cat.name}
                          </option>
                        ))}
                      </FormSelect>
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>角色描述</FormLabel>
                      <FormTextarea
                        value={newPrompt.content}
                        onChange={(e) => setNewPrompt({...newPrompt, content: e.target.value})}
                        placeholder="描述這個角色的專業背景、知識領域和行為特徵..."
                        required
                      />
                    </FormGroup>
                    <FormActions>
                      <SecondaryButton type="button" onClick={() => setShowAddPrompt(false)}>
                        取消
                      </SecondaryButton>
                      <Button type="submit">
                        新增角色
                      </Button>
                    </FormActions>
                  </AddPromptForm>
                )}

                <PromptList>
                  {filteredPrompts.map(prompt => (
                    <PromptItem key={prompt.id}>
                      <PromptTitle>
                        {prompt.title}
                        <PromptActions>
                          <ApplyButton
                            onClick={() => handleApplyPrompt(prompt)}
                            className={appliedPromptId === prompt.id ? 'applied' : ''}
                          >
                            {appliedPromptId === prompt.id ? '已套用' : '套用'}
                          </ApplyButton>
                          <CopyButton
                            onClick={() => handleCopyPrompt(prompt)}
                            className={copiedPromptId === prompt.id ? 'copied' : ''}
                          >
                            {copiedPromptId === prompt.id ? '已複製' : '複製'}
                          </CopyButton>
                          {prompt.isCustom && isLoggedIn && (
                            <DeleteButton onClick={() => handleDeletePrompt(prompt.id)}>
                              刪除
                            </DeleteButton>
                          )}
                        </PromptActions>
                      </PromptTitle>
                      <PromptContent>
                        {prompt.content}
                      </PromptContent>
                    </PromptItem>
                  ))}
                </PromptList>
              </ContentArea>
            </PopupContent>
          </PresetPromptsPopup>
        </>
      )}
    </>
  );
};

// 在 Settings 件外部定義語言選項常量
const LANGUAGE_OPTIONS = [
  { 
    value: 'zh-TW', 
    label: '繁體中文', 
    prompt: '請以繁體中文回答：' 
  },
  { 
    value: 'zh-CN', 
    label: '简体中文', 
    prompt: '请用简体中文回答：' 
  },
  { 
    value: 'en', 
    label: 'English', 
    prompt: 'Please respond in English:' 
  },
  { 
    value: 'ja', 
    label: '日本語', 
    prompt: '日本語で回答てください：' 
  },
  { 
    value: 'ko', 
    label: '한국어', 
    prompt: '한국어로 변해 주세:' 
  },
  { 
    value: 'es', 
    label: 'Español', 
    prompt: 'Por favor, responda en español:' 
  },
  { 
    value: 'fr', 
    label: 'Français', 
    prompt: 'Veuillez répondre en français :' 
  },
  { 
    value: 'de', 
    label: 'Deutsch', 
    prompt: 'Bitte antworten Sie auf Deutsch:' 
  },
  { 
    value: 'it', 
    label: 'Italiano', 
    prompt: 'Si prega di rispondere in italiano:' 
  },
  { 
    value: 'ru', 
    label: 'Русский', 
    prompt: 'Пожалуйста, ответьте на русском языке:' 
  },
  { 
    value: 'pt', 
    label: 'Português', 
    prompt: 'Por favor, responda em português:' 
  },
  { 
    value: 'vi', 
    label: 'Tiếng Việt', 
    prompt: 'Vui lòng trả lời bằng tiếng Việt:' 
  },
  { 
    value: 'th', 
    label: 'ไทย', 
    prompt: 'กรุณาตอบเป็นภาษาไทย:' 
  },
  { 
    value: 'ar', 
    label: 'العربية', 
    prompt: 'الرجاء الإجابة باللغة العربية:' 
  },
  { 
    value: 'hi', 
    label: 'हिंदी', 
    prompt: 'कृपया हिंदी में उत्तर दें:' 
  }
] as const;

const LoginContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2.5rem;
  background: ${props => props.theme.background.paper};
  border-radius: 12px;
  box-shadow: ${props => props.theme.shadows.lg};
  min-width: 320px;
  max-width: 90%;
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.98);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;

const LoginTitle = styled.h2`
  color: ${props => props.theme.text.primary};
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

const LoginDescription = styled.p`
  color: ${props => props.theme.text.secondary};
  margin: 0;
  font-size: 1rem;
  text-align: center;
`;

export default Settings; 