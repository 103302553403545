import type { Model } from './index';

export interface ModelProvider {
  name: string;
  models: Model[];
}

export const SUPPORTED_PROVIDERS: ModelProvider[] = [
  {
    name: 'OpenAI',
    models: [
      { id: 'gpt-4o-2024-11-20', name: 'GPT-4o (2024-11-20)', contextWindow: 128000, provider: 'openai' },
      { id: 'o1-preview-2024-09-12', name: 'o1-preview (2024-09-12)', contextWindow: 128000, provider: 'openai' },
      { id: 'o1-preview', name: 'o1-preview', contextWindow: 128000, provider: 'openai' },
      { id: 'o1-mini-2024-09-12', name: 'o1-mini (2024-09-12)', contextWindow: 128000, provider: 'openai' },
      { id: 'o1-mini', name: 'o1-mini', contextWindow: 128000, provider: 'openai' },
      { id: 'chatgpt-4o-latest', name: 'ChatGPT-4o Latest', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4o-mini', name: 'GPT-4o mini', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4o-mini-2024-07-18', name: 'GPT-4o mini 2024-07-18', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4o-2024-08-06', name: 'GPT-4o 2024-08-06', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4o', name: 'GPT-4o', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4o-2024-05-13', name: 'GPT-4o-2024-05-13', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4-turbo', name: 'GPT-4 Turbo', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4-turbo-2024-04-09', name: 'GPT-4 Turbo 2024-04-09', contextWindow: 128000, provider: 'openai' },
      { id: 'dall-e-3', name: 'Dall-E 3', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4-vision-preview', name: 'GPT-4 1106 Preview', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4-0125-preview', name: 'GPT-4 0125 Preview', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4-turbo-preview', name: 'GPT-4 Turbo Preview', contextWindow: 128000, provider: 'openai' },
      { id: 'gpt-4', name: 'GPT-4', contextWindow: 8192, provider: 'openai' },
      { id: 'gpt-4-0613', name: 'GPT-4 0613', contextWindow: 8192, provider: 'openai' },
      { id: 'gpt-4-0314', name: 'GPT-4 0314', contextWindow: 8192, provider: 'openai' },
      { id: 'gpt-4-32k', name: 'GPT-4 32k', contextWindow: 32768, provider: 'openai' },
      { id: 'gpt-4-32k-0613', name: 'GPT-4 32k 0613', contextWindow: 32768, provider: 'openai' },
      { id: 'gpt-4-32k-0314', name: 'GPT-4 32k 0314', contextWindow: 32768, provider: 'openai' },
      { id: 'gpt-3.5-turbo', name: 'GPT-3.5 Turbo', contextWindow: 4096, provider: 'openai' },
      { id: 'gpt-3.5-turbo-0613', name: 'GPT-3.5 Turbo 0613', contextWindow: 4096, provider: 'openai' },
      { id: 'gpt-3.5-turbo-0301', name: 'GPT-3.5 Turbo 0301', contextWindow: 4096, provider: 'openai' },
      { id: 'gpt-3.5-turbo-1106', name: 'GPT-3.5 Turbo 1106', contextWindow: 4096, provider: 'openai' },
      { id: 'gpt-3.5-turbo-0125', name: 'GPT-3.5 Turbo 0125', contextWindow: 4096, provider: 'openai' },
      { id: 'gpt-3.5-turbo-instruct', name: 'GPT-3.5 Turbo Instruct', contextWindow: 4096, provider: 'openai' },
      { id: 'gpt-3.5-turbo-16k', name: 'GPT-3.5 Turbo 16k', contextWindow: 16384, provider: 'openai' },
      { id: 'gpt-3.5-turbo-16k-0613', name: 'GPT-3.5 Turbo 16k 0613', contextWindow: 16384, provider: 'openai' }
    ]
  },
  {
    name: 'Anthropic',
    models: [
      { id: 'claude-3-5-haiku-20241022', name: 'Claude 3.5 Haiku 20241022', contextWindow: 200000, provider: 'anthropic' },
      { id: 'claude-3-5-sonnet-20241022', name: 'Claude 3.5 Sonnet 20241022', contextWindow: 200000, provider: 'anthropic' },
      { id: 'claude-3-5-sonnet-20240620', name: 'Claude 3.5 Sonnet 20240620', contextWindow: 200000, provider: 'anthropic' },
      { id: 'claude-3-sonnet-20240229', name: 'Claude 3 Sonnet 20240229', contextWindow: 200000, provider: 'anthropic' },
      { id: 'claude-3-haiku-20240307', name: 'Claude 3 Haiku 20240307', contextWindow: 200000, provider: 'anthropic' },
      { id: 'claude-3-opus-20240229', name: 'Claude 3 Opus 20240229', contextWindow: 200000, provider: 'anthropic' },
    ]
  },
  {
    name: 'Google',
    models: [
      { id: 'gemini-2.0-flash-exp', name: 'Gemini 2.0 Flash Experimental', contextWindow: 32000, provider: 'google' },
      { id: 'gemini-exp-1206', name: 'Gemini Experimental 1206', contextWindow: 32000, provider: 'google' },
      { id: 'gemini-1.5-pro-002', name: 'Gemini 1.5 Pro 002', contextWindow: 32000, provider: 'google' },
      { id: 'gemini-1.5-flash-002', name: 'Gemini 1.5 Flash 002', contextWindow: 32000, provider: 'google' },
      { id: 'gemini-1.5-pro-exp-0801', name: 'Gemini 1.5 Pro Experimental 0801', contextWindow: 32000, provider: 'google' },
      { id: 'gemini-1.5-flash-exp-0827', name: 'Gemini 1.5 Flash Experimental 0827', contextWindow: 32000, provider: 'google' },
      { id: 'gemini-1.5-flash-latest', name: 'Gemini 1.5 Flash', contextWindow: 32000, provider: 'google' },
      { id: 'gemini-1.5-pro-latest', name: 'Gemini 1.5 Pro', contextWindow: 32000, provider: 'google' },
      { id: 'gemma-2-9b', name: 'Gemma 2 9B', contextWindow: 32000, provider: 'google' },
      { id: 'gemma-2-27b', name: 'Gemma 2 27B', contextWindow: 32000, provider: 'google' },
    ]
  },
  {
    name: 'Mistral',
    models: [
      { id: 'mistral-large-2411', name: 'Mistral Large 2411', contextWindow: 32000, provider: 'mistral' },
      { id: 'mistral-large-2407', name: 'Mistral Large 2407', contextWindow: 32000, provider: 'mistral' },
      { id: 'pixtral-large-2411', name: 'Pixtral Large 2411', contextWindow: 32000, provider: 'mistral' },
      { id: 'ministral-3b', name: 'Ministral 3B', contextWindow: 32000, provider: 'mistral' },
      { id: 'ministral-8b', name: 'Ministral 8B', contextWindow: 32000, provider: 'mistral' },
      { id: 'pixtral-12b', name: 'Pixtral 12B', contextWindow: 32000, provider: 'mistral' },
      { id: 'mixtral-8x7b-instruct', name: 'Mixtral 8x7B Instruct', contextWindow: 32000, provider: 'mistral' },
      { id: 'mistral-7b-instruct', name: 'Mistral 7B Instruct', contextWindow: 32000, provider: 'mistral' },
      { id: 'mistral-7b-instruct-v0.3', name: 'Mistral 7B Instruct v0.3', contextWindow: 32000, provider: 'mistral' },
    ]
  },
  {
    name: 'Meta',
    models: [
      { id: 'llama-3.3-70b-instruct', name: 'Llama 3.3 70B Instruct', contextWindow: 4096, provider: 'meta' },
      { id: 'llama-3.2-3b-instruct', name: 'Llama 3.2 3B Instruct', contextWindow: 4096, provider: 'meta' },
      { id: 'llama-3.2-1b-instruct', name: 'Llama 3.2 1B Instruct', contextWindow: 4096, provider: 'meta' },
      { id: 'llama-3.2-90b-vision-instruct', name: 'Llama 3.2 90B Vision Instruct', contextWindow: 4096, provider: 'meta' },
      { id: 'llama-3.2-11b-vision-instruct', name: 'Llama 3.2 11B Vision Instruct', contextWindow: 4096, provider: 'meta' },
      { id: 'llama-3.1-8b-instruct', name: 'Llama 3.1 8B Instruct', contextWindow: 4096, provider: 'meta' },
      { id: 'llama-3.1-70b-instruct', name: 'Llama 3.1 70B Instruct', contextWindow: 4096, provider: 'meta' },
      { id: 'llama-3.1-405b', name: 'Llama 3.1 405B', contextWindow: 4096, provider: 'meta' },
      { id: 'llama-3.1-405b-instruct', name: 'Llama 3.1 405B Instruct', contextWindow: 4096, provider: 'meta' },
    ]
  },
  {
    name: 'Microsoft',
    models: [
      { id: 'phi-3.5-mini-128k-instruct', name: 'Phi-3.5 Mini 128K Instruct', contextWindow: 4096, provider: 'microsoft' },
    ]
  },
  {
    name: 'Alibaba',
    models: [
      { id: 'qwq-32b-preview', name: 'QwQ 32B Preview', contextWindow: 32000, provider: 'alibaba' },
      { id: 'qwen-2.5-coder-32b-instruct', name: 'Qwen2.5 Coder 32B Instruct', contextWindow: 32000, provider: 'alibaba' },
      { id: 'qwen-2.5-7b-instruct', name: 'Qwen 2.5 7B Instruct', contextWindow: 32000, provider: 'alibaba' },
      { id: 'qwen-2-vl-72b-instruct', name: 'Qwen2-VL 72B Instruct', contextWindow: 32000, provider: 'alibaba' },
      { id: 'qwen-2.5-72b-instruct', name: 'Qwen2.5 72B Instruct', contextWindow: 32000, provider: 'alibaba' },
    ]
  },
  {
    name: 'NVIDIA',
    models: [
      { id: 'llama-3.1-nemotron-70b-instruct', name: 'Llama 3.1 Nemotron 70B Instruct', contextWindow: 32000, provider: 'nvidia' },
    ]
  },
  {
    name: 'xAI',
    models: [
      { id: 'grok-vision-beta', name: 'Grok Vision Beta', contextWindow: 32000, provider: 'xa' },
      { id: 'grok-beta', name: 'Grok Beta', contextWindow: 32000, provider: 'xa' },
    ]
  },
  {
    name: 'Amazon',
    models: [
      { id: 'nova-lite-v1', name: 'Nova Lite 1.0', contextWindow: 32000, provider: 'amazon' },
      { id: 'nova-micro-v1', name: 'Nova Micro 1.0', contextWindow: 32000, provider: 'amazon' },
      { id: 'nova-pro-v1', name: 'Nova Pro 1.0', contextWindow: 32000, provider: 'amazon' }
    ]
  }
]; 