interface SettingsParams {
  key?: string;
  url?: string;
}

export const parseSettingsFromUrl = (): SettingsParams | null => {
  try {
    const hash = window.location.hash;
    if (!hash) return null;

    const settingsMatch = hash.match(/#\/\?settings=(.*)/);
    if (!settingsMatch) return null;

    const decodedSettings = decodeURIComponent(settingsMatch[1]);
    const settings: SettingsParams = JSON.parse(decodedSettings);

    return {
      key: settings.key,
      url: settings.url
    };
  } catch (error) {
    console.error('Error parsing URL settings:', error);
    return null;
  }
}; 