import type { ChatSession, Model } from '../types';

export const StorageService = {
  saveChatSession(session: ChatSession): void {
    const sessions = this.getAllSessions();
    sessions[session.id] = {
      ...session,
      isStarred: session.isStarred ?? false,
      updatedAt: Date.now()
    };
    localStorage.setItem('chat_sessions', JSON.stringify(sessions));
    window.dispatchEvent(new Event('storage'));
  },

  getAllSessions(): Record<string, ChatSession> {
    const sessions = localStorage.getItem('chat_sessions');
    return sessions ? JSON.parse(sessions) : {};
  },

  getSession(id: string): ChatSession | null {
    const sessions = this.getAllSessions();
    return sessions[id] || null;
  },

  deleteSession(id: string): void {
    const sessions = this.getAllSessions();
    delete sessions[id];
    localStorage.setItem('chat_sessions', JSON.stringify(sessions));
  },

  saveSettings(settings: any): void {
    localStorage.setItem('chat_settings', JSON.stringify(settings));
  },

  getSettings(): any {
    const settings = localStorage.getItem('chat_settings');
    return settings ? JSON.parse(settings) : null;
  },

  getCustomModels(): Model[] {
    const models = localStorage.getItem('custom_models');
    return models ? JSON.parse(models) : [];
  },

  addCustomModel(model: Model): void {
    const models = this.getCustomModels();
    models.push(model);
    localStorage.setItem('custom_models', JSON.stringify(models));
  },

  removeCustomModel(modelId: string): void {
    const models = this.getCustomModels();
    const filteredModels = models.filter(model => model.id !== modelId);
    localStorage.setItem('custom_models', JSON.stringify(filteredModels));
  },

  getStarredSessions(): ChatSession[] {
    const sessions = this.getAllSessions();
    return Object.values(sessions)
      .filter(session => session.isStarred)
      .sort((a, b) => b.updatedAt - a.updatedAt);
  }
}; 