export const lightTheme = {
  mode: 'light',
  primary: {
    main: '#5436DA',
    light: '#7B6EE6',
    dark: '#4530B3',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#F7F7F8',
    light: '#FFFFFF',
    dark: '#EAEAEA',
    hover: '#EAEAEA'
  },
  background: {
    default: '#FFFFFF',
    paper: '#F7F7F8',
    sidebar: '#F7F7F8',
    hover: '#EAEAEA',
    active: '#E5E5E5'
  },
  text: {
    primary: '#1A1A1A',
    secondary: '#666666',
    disabled: '#999999'
  },
  border: {
    main: '#E5E5E5',
    light: '#F0F0F0',
    dark: '#D9D9D9'
  },
  status: {
    error: '#DC2626',
    warning: '#F59E0B',
    success: '#059669',
    info: '#5436DA'
  },
  shadows: {
    sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
    md: '0 2px 4px rgba(0, 0, 0, 0.05)',
    lg: '0 4px 6px rgba(0, 0, 0, 0.05)'
  },
  error: {
    main: '#ff4444',
    light: '#ff6b6b',
    dark: '#cc0000'
  }
};

export const darkTheme = {
  mode: 'dark',
  primary: {
    main: '#7B6EE6',
    light: '#9C92EC',
    dark: '#5436DA',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#2D2D2D',
    light: '#3D3D3D',
    dark: '#1D1D1D',
    hover: '#3D3D3D'
  },
  background: {
    default: '#1A1A1A',
    paper: '#2D2D2D',
    sidebar: '#2D2D2D',
    hover: '#3D3D3D',
    active: '#2A2A2A'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#B3B3B3',
    disabled: '#666666'
  },
  border: {
    main: '#3D3D3D',
    light: '#4D4D4D',
    dark: '#2D2D2D'
  },
  status: {
    error: '#EF4444',
    warning: '#F59E0B',
    success: '#10B981',
    info: '#7B6EE6'
  },
  shadows: {
    sm: '0 1px 2px rgba(0, 0, 0, 0.2)',
    md: '0 2px 4px rgba(0, 0, 0, 0.2)',
    lg: '0 4px 6px rgba(0, 0, 0, 0.2)'
  },
  error: {
    main: '#ff6b6b',
    light: '#ff8787',
    dark: '#ff4444'
  }
}; 