import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus, vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Message, Model, Settings, ChatSession } from '../types';  // 添加 ChatSession
import { StorageService } from '../services/storage';
import { SUPPORTED_PROVIDERS, ModelProvider } from '../types/models';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import 'katex/dist/katex.css';
import { InlineMath, BlockMath } from 'react-katex';
import katex from 'katex';
import { SearchService } from '../services/search';

// 在組件文件中直接定義 Attachment 介面
interface Attachment {
  file: File;
  preview?: string;
  type: 'file' | 'image' | 'code';
}

// 添加語言選項常量
const LANGUAGE_OPTIONS = [
  { value: 'zh-TW', label: '繁體中文', prompt: '請以繁體中文回答：' },
  { value: 'en', label: 'English', prompt: 'Please response in English:' }
] as const;

// 在檔案開頭添加 SettingsType 的定義
type SettingsType = {
  apiKey: string;
  baseUrl: string;
  provider: string;
  currentModel: string;
  prePrompt?: string;
  compressionModel?: string;
  theme?: 'light' | 'dark';
  temperature?: number;
  responseLanguage?: 'zh-TW' | 'en';
  autoGenerateTitle?: boolean;
  enableWebSearch?: boolean;
};

interface ChatAreaProps {
  sessionId: string | null;
  settings: SettingsType;  // 使用 SettingsType
  isDarkMode: boolean;
  setSettings: (settings: SettingsType) => void;  // 這裡也要修改
  onDelete?: () => void;
  onThemeToggle: () => void;
  onTitleChange?: (sessionId: string, title: string) => void;
  isSidebarCollapsed: boolean;
  onRefreshTitle?: (sessionId: string) => void;
  sessions: ChatSession[];  // 添加這行
}

const ChatContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  background: ${props => props.theme.background.default};
  box-shadow: ${props => props.theme.shadows.sm};
  overflow: hidden;
`;

const ChatHeader = styled.div`
  padding: 1rem 2rem;
  background: ${props => props.theme.background.paper};
  border-bottom: 1px solid ${props => props.theme.border.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${props => props.theme.shadows.sm};
  position: relative;
  z-index: 100;
  gap: 2rem;
  margin-top: 4px; // 為浮動按鈕預留空間
`;

const HeaderTitle = styled.div<{ isSidebarCollapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  margin-left: ${props => props.isSidebarCollapsed ? '100px' : '0'};
  transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const MainTitle = styled.h1`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${props => props.theme.text.primary};
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;

  &::after {
    content: '✎';
    font-size: 1rem;
    opacity: 0.4;
    transition: opacity 0.2s ease;
  }

  &:hover {
    background: ${props => props.theme.background.default};
    
    &::after {
      opacity: 0.8;
    }
  }
`;

const SubTitle = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.text.secondary};
`;

const MessageList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.background.paper};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.border.main};
    border-radius: 3px;
  }
`;

const MessageItem = styled.div<{ role: string }>`
  position: relative;
  padding: 1rem 1.25rem;
  padding-bottom: 2.5rem; // 增加底部內邊距，為時間戳記預留空間
  margin: ${props => props.role === 'assistant' ? '1.5rem 0 0.5rem 160px' : '0.5rem 0'};
  width: fit-content;
  min-width: 60px;
  max-width: ${props => props.role === 'user' ? '60%' : '70%'};
  font-size: 0.9375rem;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: text;
  cursor: text;
  height: auto;
  display: inline-block;
  backdrop-filter: blur(8px);
  
  ${props => props.role === 'user' ? `
    margin-left: auto;
    margin-right: 1rem;
    background: linear-gradient(135deg, 
      ${props.theme.primary.main}f0,
      ${props.theme.primary.dark}f0
    );
    color: ${props.theme.primary.contrastText};
    border-radius: 18px 18px 4px 18px;
    box-shadow: 0 2px 12px ${props.theme.primary.main}30;
    align-self: flex-end;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 16px ${props.theme.primary.main}40;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 18px 18px 4px 18px;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
      pointer-events: none;
    }
  ` : props.role === 'assistant' ? `
    margin-right: auto;
    margin-left: 1rem;
    background: ${props.theme.background.paper}f8;
    color: ${props.theme.text.primary};
    border-radius: 18px 18px 18px 4px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
    border: 1px solid ${props.theme.border.main}60;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
      border-color: ${props.theme.border.main};
    }

    &::before {
      content: '';
      position: absolute;
      left: -6px;
      bottom: 0;
      width: 12px;
      height: 12px;
      background: ${props.theme.background.paper}f8;
      border-left: 1px solid ${props.theme.border.main}60;
      border-bottom: 1px solid ${props.theme.border.main}60;
      clip-path: polygon(100% 0, 100% 100%, 0 100%);
    }

    &::after {
      content: '';
      position: absolute;
      inset: -1px;
      border-radius: 18px 18px 18px 4px;
      pointer-events: none;
      background: linear-gradient(
        to right,
        transparent,
        ${props.theme.background.paper}10
      );
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover::after {
      opacity: 1;
    }
  ` : `
    background: ${props.theme.secondary.light}90;
    color: ${props.theme.text.secondary};
    margin: 1rem auto;
    font-size: 0.8125rem;
    padding: 0.75rem 1rem;
    border-radius: 12px;
    opacity: 0.8;
    text-align: center;
    max-width: 80%;
    backdrop-filter: blur(4px);
    
    &:hover {
      opacity: 1;
      transform: translateY(-1px);
    }
  `}

  // 添加滑鼠懸停時的光暈效果
  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    background: radial-gradient(
      circle at var(--x, 50%) var(--y, 50%),
      ${props => props.role === 'user' 
        ? 'rgba(255, 255, 255, 0.1)' 
        : props.theme.primary.main + '08'} 0%,
      transparent 60%
    );
  }

  &:hover::before {
    opacity: 1;
  }
`;

const MessageHeader = styled.div<{ role: string }>`
  position: absolute;
  top: -1.25rem;
  ${props => props.role === 'user' ? 'right: 0;' : 'left: 0;'}
  font-size: 0.6875rem;
  color: ${props => props.theme.text.secondary};
  opacity: 0;
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  ${MessageItem}:hover & {
    opacity: 1;
  }
`;

const MessageTime = styled.span`
  opacity: 0.7;
  font-size: 0.625rem;
`;

const InputArea = styled.div`
  padding: 1rem;
  background: ${props => props.theme.background.paper};
  border-top: 1px solid ${props => props.theme.border.main};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: ${props => props.theme.text.secondary};
  opacity: 0.6;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    opacity: 1;
    background: ${props => props.theme.secondary.hover};
    color: ${props => props.theme.status.error};
  }

  &:active {
    transform: translateY(-50%) scale(0.95);
  }
`;

const Input = styled.textarea`
  flex: 1;
  padding: 0.875rem 1rem;
  min-height: 48px;
  max-height: 150px;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 24px;
  background: ${props => props.theme.background.default};
  color: ${props => props.theme.text.primary};
  font-size: 0.9375rem;
  line-height: 1.4;
  resize: none;
  overflow-y: auto;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}15;
  }

  &::placeholder {
    color: ${props => props.theme.text.disabled};
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  background: ${props => props.theme.primary.main};
  color: ${props => props.theme.primary.contrastText};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    transition: transform 0.2s ease;
  }

  &:hover {
    background: ${props => props.theme.primary.dark};
    
    svg {
      transform: translateY(-1px);
    }
  }

  &:disabled {
    background: ${props => props.theme.text.disabled};
    cursor: not-allowed;
  }
`;

const SendButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover:not(:disabled) {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.7;
  }

  svg {
    width: 20px;
    height: 20px;
    transform: translateX(1px);
  }
`;

const LoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: ${props => props.theme.text.secondary};

  &::after {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid ${props => props.theme.border.main};
    border-top-color: ${props => props.theme.primary.main};
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.div`
  margin: 1rem;
  padding: 1rem 1.5rem;
  background: ${props => props.theme.status.error}15;
  color: ${props => props.theme.status.error};
  border-radius: 8px;
  border-left: 4px solid ${props => props.theme.status.error};
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  animation: slideIn 0.3s ease;

  @keyframes slideIn {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const ExportIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="7 10 12 15 17 10" />
    <line x1="12" y1="15" x2="12" y2="3" />
  </svg>
);

const UploadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="17 8 12 3 7 8" />
    <line x1="12" y1="3" x2="12" y2="15" />
  </svg>
);

const ClearIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"/>
    <line x1="15" y1="9" x2="9" y2="15"/>
    <line x1="9" y1="9" x2="15" y2="15"/>
  </svg>
);

const AttachmentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48" />
  </svg>
);

const FileIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
    <polyline points="13 2 13 9 20 9" />
  </svg>
);

const ImageIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
    <circle cx="8.5" cy="8.5" r="1.5" />
    <polyline points="21 15 16 10 5 21" />
  </svg>
);

const CodeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="16 18 22 12 16 6" />
    <polyline points="8 6 2 12 8 18" />
  </svg>
);

const DeleteButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: ${props => props.theme.text.secondary};
  opacity: 0.6;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    opacity: 1;
    background: ${props => props.theme.secondary.hover};
    color: ${props => props.theme.status.error};
  }

  &:active {
    transform: translateY(-50%) scale(0.95);
  }
`;

const handleApiError = (error: any) => {
  console.error('API Error:', error);
  if (error.response) {
    switch (error.response.status) {
      case 401:
        return '驗證失敗，請檢查 API 金鑰';
      case 403:
        return '無權限訪問該資源';
      case 429:
        return 'API 請求次數超限';
      default:
        return `請求失敗 (${error.response.status}): ${error.response.data?.message || '未知錯��'}`;
    }
  }
  return '網絡錯，請檢查網絡連';
};

// 添加 Markdown 樣式組
const MarkdownContent = styled.div`
  font-size: 1rem;
  line-height: 1.6;
  color: inherit;

  h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem;
    line-height: 1.25;
  }

  h1 { font-size: 2em; }
  h2 { font-size: 1.5em; }
  h3 { font-size: 1.25em; }

  p {
    margin: 1rem 0;
  }

  ul, ol {
    margin: 1rem 0;
    padding-left: 2rem;
  }

  li {
    margin: 0.5rem 0;
  }

  a {
    color: ${props => props.theme.primary.main};
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    border-left: 4px solid ${props => props.theme.primary.main};
    background: ${props => props.theme.background.paper};
    font-style: italic;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
  }

  th, td {
    padding: 0.5rem;
    border: 1px solid ${props => props.theme.border.main};
  }

  th {
    background: ${props => props.theme.background.paper};
    font-weight: 600;
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }

  hr {
    margin: 2rem 0;
    border: none;
    border-top: 1px solid ${props => props.theme.border.main};
  }

  code:not(pre code) {
    padding: 0.2em 0.4em;
    background: ${props => props.theme.background.paper};
    border-radius: 3px;
    font-family: 'Fira Code', monospace;
    font-size: 0.9em;
  }
`;

// 添加代碼塊相關的樣式組
const CodeBlock = styled.div`
  margin: 1rem 0;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
`;

// 添加 CodeActions 樣式組件
const CodeActions = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

// 其他相關的樣式組件保持不變
const CodeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: ${props => props.theme.background.paper};
  border-bottom: 1px solid ${props => props.theme.border.main};
`;

const LanguageLabel = styled.span`
  font-size: 0.75rem;
  color: ${props => props.theme.text.secondary};
  font-family: monospace;
`;

const CopyButton = styled.button`
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  color: ${props => props.theme.text.secondary};
  background: transparent;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    color: ${props => props.theme.text.primary};
    border-color: ${props => props.theme.primary.main};
  }

  &:active {
    transform: scale(0.95);
  }

  &.copied {
    background: ${props => props.theme.primary.main};
    color: white;
    border-color: ${props => props.theme.primary.main};
  }
`;

// 修改 CodeBlockComponent，添加行號功能
const CodeBlockComponent: React.FC<{
  language: string;
  code: string;
  isDarkMode: boolean;
}> = ({ language, code, isDarkMode }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const lines = code.split('\n');
  const shouldCollapse = lines.length > 10;
  
  useEffect(() => {
    setIsCollapsed(shouldCollapse);
  }, [shouldCollapse]);

  const copyCode = () => {
    navigator.clipboard.writeText(code);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const displayedCode = isCollapsed ? 
    lines.slice(0, 10).join('\n') + '\n// ...' : 
    code;

  return (
    <CodeContainer>
      <CodeHeader>
        <LanguageLabel>{language}</LanguageLabel>
        <CodeActions>
          {shouldCollapse && (
            <CollapseButton 
              onClick={() => setIsCollapsed(!isCollapsed)}
              title={isCollapsed ? "展開完整程式碼" : "摺疊程式碼"}
            >
              {isCollapsed ? (
                <>
                  <ExpandIcon /> 展開 ({lines.length} 行)
                </>
              ) : (
                <>
                  <CollapseIcon /> 摺疊
                </>
              )}
            </CollapseButton>
          )}
          <CopyButton 
            onClick={copyCode}
            className={isCopied ? 'copied' : ''}
          >
            {isCopied ? '已複製！' : '複製程式碼'}
          </CopyButton>
        </CodeActions>
      </CodeHeader>
      <CodeContent>
        <LineNumbers>
          {displayedCode.split('\n').map((_, i) => (
            <LineNumber key={i}>{i + 1}</LineNumber>
          ))}
        </LineNumbers>
        <SyntaxHighlighter
          language={language}
          style={isDarkMode ? vscDarkPlus : vs}
          customStyle={{
            margin: 0,
            padding: '1rem 0.5rem',
            background: isDarkMode ? '#1E1E1E' : '#F8F8F8',
            borderRadius: '0 0 8px 8px',
            maxHeight: isCollapsed ? '300px' : 'none',
            transition: 'max-height 0.3s ease',
            flex: 1,
          }}
          showLineNumbers={false} // 因為我們自己實現了行號
        >
          {displayedCode}
        </SyntaxHighlighter>
      </CodeContent>
      {isCollapsed && (
        <CollapsedOverlay onClick={() => setIsCollapsed(false)}>
          <ExpandIcon />
          點擊展開完整程式碼 ({lines.length} 行)
        </CollapsedOverlay>
      )}
    </CodeContainer>
  );
};

// 添加新的樣式組件
const CodeContent = styled.div`
  display: flex;
  overflow-x: auto;
`;

const LineNumbers = styled.div`
  padding: 1rem 0.5rem;
  background: ${props => props.theme.background.paper};
  border-right: 1px solid ${props => props.theme.border.main}30;
  user-select: none;
  text-align: right;
  color: ${props => props.theme.text.secondary};
  opacity: 0.5;
  min-width: 3rem;
`;

const LineNumber = styled.div`
  font-size: 0.875rem;
  line-height: 1.4;
  font-family: monospace;
  padding: 0 0.5rem;
`;

const CollapseButton = styled.button`
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  color: ${props => props.theme.text.secondary};
  background: transparent;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    color: ${props => props.theme.text.primary};
    border-color: ${props => props.theme.primary.main};
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

const CollapsedOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: ${props => props.theme.background.paper}95;
  backdrop-filter: blur(4px);
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: 0 0 8px 8px;
  transition: all 0.2s ease;
  border-top: 1px solid ${props => props.theme.border.main}30;

  &:hover {
    background: ${props => props.theme.background.paper};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

// 添加展開/摺疊圖標
const ExpandIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polyline points="7 13 12 18 17 13"/>
    <polyline points="7 6 12 11 17 6"/>
  </svg>
);

const CollapseIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polyline points="17 11 12 6 7 11"/>
    <polyline points="17 18 12 13 7 18"/>
  </svg>
);

// 修改現有的 CodeContainer 樣式
const CodeContainer = styled.div`
  position: relative;
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
`;

// 添加模型圖標
const ModelIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
  </svg>
);

// 修 ProviderIcons 的定義使用方式
const ProviderIcons: Record<string, React.FC<{ size?: number }>> = {
  openai: ({ size = 20 }) => (
    <img src="/icons/openai-icon.png" width={size} height={size} alt="OpenAI" />
  ),
  anthropic: ({ size = 20 }) => (
    <img src="/icons/anthropic-icon.png" width={size} height={size} alt="Anthropic" />
  ),
  google: ({ size = 20 }) => (
    <img src="/icons/google-icon.png" width={size} height={size} alt="Google" />
  ),
  meta: ({ size = 20 }) => (
    <img src="/icons/meta-icon.webp" width={size} height={size} alt="Meta" />
  ),
  microsoft: ({ size = 20 }) => (
    <img src="/icons/microsoft-icon.png" width={size} height={size} alt="Microsoft" />
  ),
  mistral: ({ size = 20 }) => (
    <img src="/icons/mistral-icon.webp" width={size} height={size} alt="Mistral" />
  ),
  alibaba: ({ size = 20 }) => (
    <img src="/icons/alibaba-icon.png" width={size} height={size} alt="Alibaba" />
  ),
  nvidia: ({ size = 20 }) => (
    <img src="/icons/nvidia-icon.png" width={size} height={size} alt="NVIDIA" />
  ),
  xai: ({ size = 20 }) => (
    <img src="/icons/xai-icon.svg" width={size} height={size} alt="xAI" />
  ),
  amazon: ({ size = 20 }) => (
    <img src="/icons/amazon-icon.png" width={size} height={size} alt="Amazon" />
  ),
};

// 添加新的樣式件
const ModelButton = styled.button`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  color: ${props => props.theme.text.primary};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    border-color: ${props => props.theme.primary.main};
  }

  svg {
    color: ${props => props.theme.text.secondary};
  }
`;

const ModelPopup = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 120px;
  left: 1rem;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadows.lg};
  padding: 1rem;
  max-height: calc(100vh - 200px);
  width: calc(100% - 2rem);
  max-width: 800px;
  overflow-y: auto;
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 1100;
  opacity: ${props => props.isOpen ? 1 : 0};
  transition: opacity 0.2s ease-in-out;
  backdrop-filter: blur(8px);
`;

const ProviderSection = styled.div`
  border-bottom: 1px solid ${props => props.theme.border.main};
  padding: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

const ProviderHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.text.secondary};
  font-size: 0.875rem;
  font-weight: 500;
`;

const ModelList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 0.75rem;
  padding: 0.5rem;
`;

const ModelOption = styled.button<{ isSelected: boolean }>`
  padding: 1rem;
  background: ${props => props.isSelected ? props.theme.primary.main + '20' : props.theme.background.default};
  border: 1px solid ${props => props.isSelected ? props.theme.primary.main : props.theme.border.main};
  border-radius: 8px;
  color: ${props => props.isSelected ? props.theme.primary.main : props.theme.text.primary};
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  height: 100%;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    border-color: ${props => props.theme.primary.main};
    transform: translateY(-2px);
  }
`;

const ModelName = styled.span`
  font-weight: 500;
`;

const ModelInfo = styled.span`
  font-size: 0.75rem;
  color: ${props => props.theme.text.secondary};
`;

// 添加一個遮罩層組件
const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  display: ${props => props.isVisible ? 'block' : 'none'};
  backdrop-filter: blur(2px);
`;

// 添加預設提示詞圖標
const PrePromptIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
    <path d="M12 8v4M12 16h.01"/>
  </svg>
);

// 添加新的樣式組件
const ButtonGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
  }
`;

const PrePromptButton = styled(ModelButton)`
  background: ${props => props.theme.background.paper};
  &:hover {
    background: ${props => props.theme.secondary.hover};
  }
`;

const PrePromptPopup = styled(ModelPopup)`
  width: 500px;
  left: 50%;
  transform: translateX(-50%);
  padding: 1.5rem;
`;

const PrePromptTextarea = styled.textarea`
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  background: ${props => props.theme.background.default};
  color: ${props => props.theme.text.primary};
  font-size: 0.875rem;
  resize: vertical;
  margin: 1rem 0;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const PopupTitle = styled.h3`
  margin: 0;
  color: ${props => props.theme.text.primary};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const SecondaryButton = styled(Button)`
  background: ${props => props.theme.secondary.main};
  color: ${props => props.theme.text.primary};
  
  &:hover {
    background: ${props => props.theme.secondary.dark};
  }
`;

// 添加發送圖標組
const SendIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
  </svg>
);

// 添加星標圖標���件
const StarIcon = ({ filled }: { filled: boolean }) => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill={filled ? "currentColor" : "none"} stroke="currentColor" strokeWidth="2">
    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
  </svg>
);

// 添加對話縮函數
const ChatArea: React.FC<ChatAreaProps> = ({ 
  sessionId, 
  settings,  // 確保從 props 中獲取 settings
  isDarkMode,
  setSettings,
  onDelete,
  onThemeToggle,
  onTitleChange,
  isSidebarCollapsed,
  onRefreshTitle,
  sessions
}): JSX.Element => {  // 明確指定返回類型為 JSX.Element
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [showAttachMenu, setShowAttachMenu] = useState(false);
  const [showModelPopup, setShowModelPopup] = useState(false);
  const [showPrePromptPopup, setShowPrePromptPopup] = useState(false);
  const [tempPrePrompt, setTempPrePrompt] = useState(settings.prePrompt || '');
  const [selectedProvider, setSelectedProvider] = useState(settings.provider);
  const [availableModels, setAvailableModels] = useState<Model[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [copiedMessageId, setCopiedMessageId] = useState<string | null>(null);
  const [editingMessageId, setEditingMessageId] = useState<string | null>(null);
  const [editedContent, setEditedContent] = useState('');
  const cursorPositionRef = useRef<number | null>(null);
  const [isStarred, setIsStarred] = useState(false);
  const [currentTitle, setCurrentTitle] = useState<string>('新對話');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [tempTitle, setTempTitle] = useState(currentTitle);

  // 添加所需的 refs
  const attachMenuRef = useRef<HTMLDivElement>(null);
  const attachButtonRef = useRef<HTMLButtonElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const codeInputRef = useRef<HTMLInputElement>(null);
  const editTextareaRef = useRef<HTMLTextAreaElement>(null);

  // 添加 AbortController 的 ref
  const abortControllerRef = useRef<AbortController | null>(null);

  // 添加 ref 用於消息列表
  const messageListRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  // 添加編輯歷史記錄的類型
  interface EditHistory {
    content: string;
    timestamp: number;
  }

  // 在 ChatArea 組件中添加新的 state
  const [editHistory, setEditHistory] = useState<EditHistory[]>([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);

  // 添加編輯歷史相關的函數
  const addToHistory = (content: string) => {
    setEditHistory(prev => [...prev, { content, timestamp: Date.now() }]);
    setCurrentHistoryIndex(editHistory.length);
  };

  const undo = () => {
    if (currentHistoryIndex > 0) {
      setCurrentHistoryIndex(prev => prev - 1);
      setEditedContent(editHistory[currentHistoryIndex - 1].content);
    }
  };

  const redo = () => {
    if (currentHistoryIndex < editHistory.length - 1) {
      setCurrentHistoryIndex(prev => prev + 1);
      setEditedContent(editHistory[currentHistoryIndex + 1].content);
    }
  };

  // 添加自動滾動效果
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (sessionId) {
      const session = StorageService.getSession(sessionId);
      if (session) {
        setMessages(session.messages);
        setIsStarred(session.isStarred || false);
        setCurrentTitle(session.title || '新對');
      } else {
        setMessages([]);
        setIsStarred(false);
        setCurrentTitle('新對話');
      }
    } else {
      setMessages([]);
      setIsStarred(false);
      setCurrentTitle('新對話');
    }
  }, [sessionId, sessions]); // 添加 sessions 作為依賴項

  // generateTitle 函數負責調用 AI 生成標題
  const generateTitle = async (message: string, aiResponse: string) => {
    try {
      const response = await fetch(`${settings.baseUrl}/v1/chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${settings.apiKey}`
        },
        body: JSON.stringify({
          model: settings.currentModel,
          messages: [
            {
              role: 'system',
              content: '你是一個標題生成器。請根據用戶的問題和AI的回答，生成一個簡短的中文標題（4-6個字）來概括這個對話的主題。請直接返回標題文字，不需要其他式或說明。'
            },
            {
              role: 'user',
              content: `用戶問題：${message}\n\nAI答：${aiResponse}`
            }
          ],
          temperature: 0.7,
          max_tokens: 50
        })
      });

      if (!response.ok) {
        throw new Error('標題生成失敗');
      }

      const data = await response.json();
      return data.choices[0].message.content.trim();
    } catch (error) {
      console.error('Error generating title:', error);
      return '對話';
    }
  };

  const handleAttachment = async (files: FileList | null, type: 'file' | 'image' | 'code') => {
    if (!files?.length) return;
    
    const newFiles = Array.from(files);
    
    if (type === 'image') {
      const imageFiles = newFiles.filter(file => file.type.startsWith('image/'));
      const previews = await Promise.all(
        imageFiles.map(async (file) => {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
            reader.readAsDataURL(file);
          });
        })
      );
      
      setAttachments(prev => [
        ...prev,
        ...imageFiles.map((file, index): Attachment => ({
          file,
          preview: previews[index],
          type: 'image'  // 直接使用字面量類型
        }))
      ]);
    } else {
      setAttachments(prev => [
        ...prev,
        ...newFiles.map((file): Attachment => ({
          file,
          type  // 因為 type 參數已經被限制為 'file' | 'image' | 'code'，所以可以直接使用
        }))
      ]);
    }
    
    setShowAttachMenu(false);
  };

  const removeAttachment = (index: number) => {
    setAttachments(prev => prev.filter((_, i) => i !== index));
  };

  // 在發送請求前組合完整的 prompt
  const getFullPrompt = (settings: SettingsType) => {  // 使用 SettingsType 而不是 Settings
    const languagePrompt = LANGUAGE_OPTIONS.find(
      (opt: { value: string; label: string; prompt: string }) => 
        opt.value === (settings.responseLanguage || 'zh-TW')  // 添加預設值
    )?.prompt || LANGUAGE_OPTIONS[0].prompt;

    return `${languagePrompt}\n${settings.prePrompt || ''}`.trim();
  };

  // 在發送消息後保存到本地存儲
  const saveSession = (newMessages: Message[]) => {
    if (sessionId) {
      const session = StorageService.getSession(sessionId);
      if (session) {
        const updatedSession = {
          ...session,
          messages: newMessages,
          updatedAt: Date.now()
        };
        StorageService.saveChatSession(updatedSession);
      }
    }
  };

  // 修改 sendMessage 函數，在更新消息後保存會話
  const handleSendMessage = async (message: string) => {
    if (!message.trim() && !attachments.length) return;
    
    // 如果有正在進行的請求，取消它
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    // 創建新的 AbortController
    abortControllerRef.current = new AbortController();
    setIsLoading(true);
    setError(null);

    try {
      let finalPrompt = message;
      let searchContext = '';

      // 如果啟用了網路搜索
      if (settings.enableWebSearch) {
        try {
          console.log('開始提取關鍵字...');
          // 1. 先用 AI 提取關鍵字
          const keywordsResponse = await fetch(`${settings.baseUrl}/v1/chat/completions`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${settings.apiKey}`
            },
            body: JSON.stringify({
              model: settings.currentModel,
              messages: [
                {
                  role: 'system',
                  content: '請從用戶的問題中提取3-5個關鍵字，直接返回關鍵字，以空格分隔。'
                },
                {
                  role: 'user',
                  content: message
                }
              ],
              temperature: 0.3
            })
          });
          
          const keywordsData = await keywordsResponse.json();
          const keywords = keywordsData.choices[0].message.content;
          console.log('提取的關鍵字:', keywords);
          
          // 2. 使用關鍵字進行網路搜索
          console.log('開始搜索...');
          const searchResults = await SearchService.searchWithDuckDuckGo(keywords);
          console.log('搜索結果:', searchResults);
          
          if (searchResults.length === 0) {
            console.log('沒有找到搜索結果，使用原始問題繼續...');
            // 可以選擇是否要通知用戶
          }
          
          // 3. 準備搜索上下文
          searchContext = searchResults.length > 0 ? `
相關網路搜索結果：
${searchResults.map(result => `
- ${result.title}
${result.snippet}
來源：${result.url}
`).join('\n')}

請基於以上搜索結果和你的知識回答問題，適當引用來源。
` : '';
          console.log('最終的搜索上下文:', searchContext);
          
          finalPrompt = searchContext + '\n\n用戶問題：' + message;
        } catch (error) {
          console.error('網路搜索過程出錯:', error);
          // 如果搜索失敗，使用原始問題繼續
        }
      }

      const newMessage: Message = {
        role: 'user',
        content: message,
        timestamp: Date.now()
      };

      // 立即更新消息列表，顯示用戶消息
      setMessages(prev => {
        const newMessages = [...prev, newMessage];
        saveSession(newMessages);
        return newMessages;
      });

      // 構建消息歷史
      let messageHistory: Message[] = [];
      
      // 處理 prePrompt
      const prePrompt = settings.prePrompt?.trim();
      if (prePrompt && messages.length === 0) {
        messageHistory = [
          {
            role: 'system',
            content: prePrompt,
            timestamp: Date.now()
          }
        ];
      }
      
      // 添加歷史消息和新消息
      messageHistory = [...messageHistory, ...messages, newMessage];

      // 發送請求給 AI
      const response = await fetch(`${settings.baseUrl}/v1/chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${settings.apiKey}`
        },
        body: JSON.stringify({
          model: settings.currentModel,
          messages: [
            {
              role: 'system',
              content: getFullPrompt(settings) + (searchContext ? '\n\n' + searchContext : '')
            },
            ...messageHistory.map(({ role, content }) => ({ role, content }))
          ],
          temperature: settings.temperature || 0.7,
          max_tokens: 2000
        })
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const data = await response.json();
      const assistantMessage: Message = {
        role: 'assistant',
        content: data.choices[0].message.content,
        timestamp: Date.now()
      };

      // 更新消息列表
      setMessages(prev => {
        const newMessages = [...prev, assistantMessage];
        saveSession(newMessages);
        return newMessages;
      });

      // 清除輸入和附件
      setInput('');
      setAttachments([]);

    } catch (err: unknown) {
      if (err instanceof Error) {
        if (err.name === 'AbortError') {
          console.log('Request cancelled');
        } else {
          console.error('API Error:', err);
          setError(err.message || '請求失敗，請檢查 API 設置');
        }
      } else {
        setError('未知錯誤');
      }
    } finally {
      setIsLoading(false);
      abortControllerRef.current = null;
    }
  };

  // 修改 getCurrentModelName 數，添類型註解
  const getCurrentModelName = (): string => {
    const provider: ModelProvider | undefined = SUPPORTED_PROVIDERS.find(
      (p: ModelProvider) => p.name.toLowerCase() === settings.provider
    );
    
    if (provider) {
      const model: Model | undefined = provider.models.find(
        (m: Model) => m.id === settings.currentModel
      );
      if (model) return model.name;
    }
    
    // 檢查自定義模
    const customModels: Model[] = JSON.parse(localStorage.getItem('custom_models') || '[]');
    const customModel: Model | undefined = customModels.find(
      (m: Model) => m.id === settings.currentModel
    );
    return customModel ? customModel.name : settings.currentModel;
  };

  // 添加點擊外部閉菜單的理
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        attachMenuRef.current && 
        attachButtonRef.current && 
        !attachMenuRef.current.contains(event.target as Node) &&
        !attachButtonRef.current.contains(event.target as Node)
      ) {
        setShowAttachMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // 當提商改變時更新可用列表
  useEffect(() => {
    const provider = SUPPORTED_PROVIDERS.find(p => p.name.toLowerCase() === selectedProvider);
    if (provider) {
      const models = [...provider.models];
      // 添加自定義模型
      const customModels = StorageService.getCustomModels()
        .filter(m => m.provider.toLowerCase() === provider.name.toLowerCase());
      setAvailableModels([...models, ...customModels]);
    }
  }, [selectedProvider]);

  // 處理提供商變更
  const handleProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newProvider = e.target.value;
    setSelectedProvider(newProvider);
    const provider = SUPPORTED_PROVIDERS.find(p => p.name.toLowerCase() === newProvider.toLowerCase());
    if (provider && provider.models.length > 0) {
      setSettings({
        ...settings,
        provider: newProvider.toLowerCase(),
        currentModel: provider.models[0].id
      });
    }
  };

  // 處理模型變更
  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSettings({
      ...settings,
      currentModel: e.target.value
    });
  };

  const handleSavePrePrompt = () => {
    setSettings({
      ...settings,
      prePrompt: tempPrePrompt
    });
    setShowPrePromptPopup(false);
  };

  // 將 compressConversation 移這，為組件方法
  const compressConversation = async (messagesToCompress: Message[]): Promise<string> => {
    try {
      const response = await fetch(`${settings.baseUrl}/chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${settings.apiKey}`
        },
        body: JSON.stringify({
          model: settings.compressionModel || 'gpt-4o-mini',
          messages: [
            {
              role: 'system',
              content: '請總結以下對話的重要信息，保持簡潔但不遺漏關鍵內。總結需要保留對後續對話有幫助的上下文信息'
            },
            {
              role: 'user',
              content: messagesToCompress.map(m => `${m.role}: ${m.content}`).join('\n\n')
            }
          ]
        })
      });

      if (!response.ok) {
        throw new Error('壓縮對話失敗');
      }

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      console.error('壓縮對話時出錯:', error);
      return messagesToCompress.map(m => m.content).join('\n\n');
    }
  };

  // 在組件卸載時取消所有請求
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // 在組件中添加自動調整入框高度的功能
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    setInput(textarea.value);
    
    // 置高度
    textarea.style.height = 'auto';
    // 設置高度
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  // 創建一個包裝組件來處理 ref
  const MessageItemWithRef = React.forwardRef<HTMLDivElement, { role: string; children: React.ReactNode }>(
    ({ role, children, ...props }, ref) => (
      <MessageItem role={role} ref={ref} {...props}>
        {children}
      </MessageItem>
    )
  );

  // 加複製消息的函數
  const copyMessage = (message: Message) => {
    navigator.clipboard.writeText(message.content).then(() => {
      setCopiedMessageId(message.timestamp?.toString() || null);
      setTimeout(() => setCopiedMessageId(null), 2000);
    });
  };

  // 添加複製按鈕樣式
  const CopyMessageButton = styled.button`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.25rem 0.5rem;
    background: ${props => props.theme.background.paper}80;
    border: 1px solid ${props => props.theme.border.main};
    border-radius: 4px;
    color: ${props => props.theme.text.secondary};
    cursor: pointer;
    opacity: 0;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.75rem;
    backdrop-filter: blur(4px);
    z-index: 10;

    ${MessageItem}:hover & {
      opacity: 1;
    }

    &:hover {
      background: ${props => props.theme.background.paper};
      color: ${props => props.theme.primary.main};
    }

    svg {
      width: 14px;
      height: 14px;
    }
  `;

  // 添加複製成功提示組件
  const CopyTooltip = styled.div<{ show: boolean }>`
    position: absolute;
    top: -2rem;
    right: 0.5rem;
    background: ${props => props.theme.background.paper};
    color: ${props => props.theme.text.primary};
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    opacity: ${props => props.show ? 1 : 0};
    transition: opacity 0.2s ease;
    pointer-events: none;
    box-shadow: ${props => props.theme.shadows.md};
    z-index: 101;
  `;

  // 添加編輯圖標
  const EditIcon = () => (
    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zM18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
    </svg>
  );

  // 添加新發送圖標
  const ResendIcon = () => (
    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M21 2v6h-6M3 12a9 9 0 0 1 15-6.7L21 8M3 22v-6h6M21 12a9 9 0 0 1-15 6.7L3 16" />
    </svg>
  );

  // 修改編輯開始函數
  const startEditing = (message: Message) => {
    setEditingMessageId(message.timestamp?.toString() || null);
    setEditedContent(message.content);
    
    // 當開始編輯時，自動調整文本區域的高度
    setTimeout(() => {
      const textarea = document.querySelector('.edit-popup-textarea') as HTMLTextAreaElement;
      if (textarea) {
        // 計算內容的行數
        const lineCount = message.content.split('\n').length;
        // 每行大約 24px（包含行高）
        const estimatedHeight = Math.min(Math.max(lineCount * 24 + 40, 150), 500);
        
        textarea.style.height = `${estimatedHeight}px`;
        textarea.focus();
        textarea.setSelectionRange(textarea.value.length, textarea.value.length);
      }
    }, 100);
  };

  // 修改 handleEditChange 函數
  const handleEditChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    cursorPositionRef.current = textarea.selectionStart;
    
    // 自調整高
    textarea.style.height = 'auto';
    const scrollHeight = Math.min(Math.max(textarea.scrollHeight, 150), 500);
    textarea.style.height = `${scrollHeight}px`;
    
    setEditedContent(textarea.value);
    addToHistory(textarea.value);
  };

  // 添加 useEffect 來恢復游標位置
  useEffect(() => {
    if (editingMessageId && cursorPositionRef.current !== null) {
      const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
      if (textarea) {
        textarea.focus();
        textarea.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
      }
    }
  }, [editedContent, editingMessageId]);

  // 保存編輯的函
  const saveEdit = async (index: number) => {
    if (!editingMessageId) return;

    const updatedMessages = [...messages];
    updatedMessages[index] = {
      ...updatedMessages[index],
      content: editedContent
    };

    setMessages(updatedMessages);
    setEditingMessageId(null);
    setEditedContent('');

    // 如果是在會話中，保存更新
    if (sessionId) {
      const session = StorageService.getSession(sessionId);
      if (session) {
        StorageService.saveChatSession({
          ...session,
          messages: updatedMessages,
          updatedAt: Date.now()
        });
      }
    }
  };

  // 修改重新發送消息的函數
  const resendMessage = async (messageIndex: number) => {
    const targetMessage = messages[messageIndex];
    const previousMessages = messages.slice(0, messageIndex);
    
    // 移除目標消息之後的所有消息
    setMessages(previousMessages);
    
    // 使用編輯後的內容（如果正在編輯）或原始內容
    const contentToSend = editingMessageId === targetMessage.timestamp?.toString()
      ? editedContent
      : targetMessage.content;

    // 清除編輯狀態
    setEditingMessageId(null);
    setEditedContent('');
    
    // 立即發送新消息
    const newMessage: Message = {
      role: 'user',
      content: contentToSend,
      timestamp: Date.now()
    };

    // 更新消息列表，立即顯示用戶消息
    setMessages(prev => [...previousMessages, newMessage]);

    // 發送消息
    try {
      const messageHistory = [...previousMessages, newMessage];
      
      const requestBody = {
        model: settings.currentModel,
        messages: messageHistory.map(({ role, content }) => ({ role, content })),
        temperature: 0.7,
        max_tokens: 2000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        stream: false
      };

      const response = await fetch(`${settings.baseUrl}/v1/chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${settings.apiKey}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(
          errorData?.error?.message || 
          `API Error: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();
      const assistantMessage: Message = {
        role: 'assistant',
        content: data.choices[0].message.content,
        timestamp: Date.now()
      };

      // 更新息列表，添加助手的回應
      setMessages(prev => [...prev, assistantMessage]);

      // 保存對話
      if (sessionId) {
        const session = StorageService.getSession(sessionId);
        if (session) {
          StorageService.saveChatSession({
            ...session,
            messages: [...messageHistory, assistantMessage],
            updatedAt: Date.now()
          });
        }
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.error('API Error:', err);
        setError(err.message || '請求失敗，請檢查 API 設置');
      } else {
        setError('未知錯誤');
      }
    }
  };

  // 修改 handleDelete 函數
  const handleDelete = () => {
    if (!sessionId) return;
    
    if (window.confirm('確定要刪除這個對話嗎？此操作無法撤銷。')) {
      StorageService.deleteSession(sessionId);
      // 調用父組件傳入的 onDelete 回調
      onDelete?.();
    }
  };

  // 添加處理星標的函數
  const handleStar = () => {
    if (!sessionId) return;
    
    const newStarredState = !isStarred;
    setIsStarred(newStarredState);
    
    const session = StorageService.getSession(sessionId);
    if (session) {
      StorageService.saveChatSession({
        ...session,
        isStarred: newStarredState,
        updatedAt: Date.now()
      });
    }
  };

  // 添加等待氣泡的樣式組件
  const LoadingBubbles = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    max-width: 100px;
    background: ${props => props.theme.background.paper};
    border: 1px solid ${props => props.theme.border.main};
    border-radius: 18px 18px 18px 4px;
    margin-left: 1rem;
  `;

  const Bubble = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme.text.secondary};
    opacity: 0.6;
    animation: bounce 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }

    @keyframes bounce {
      0%, 80%, 100% { 
        transform: scale(0);
      } 
      40% { 
        transform: scale(1);
      }
    }
  `;

  // 添加等待氣泡組件
  const TypingIndicator = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    max-width: 100px;
    background: ${props => props.theme.background.paper}80;
    backdrop-filter: blur(8px);
    border-radius: 18px 18px 18px 4px;
    margin-left: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  `;

  const TypingDot = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme.text.secondary};
    opacity: 0.6;
    animation: bounce 1.4s infinite ease-in-out both;

    &:nth-child(1) { animation-delay: -0.32s; }
    &:nth-child(2) { animation-delay: -0.16s; }

    @keyframes bounce {
      0%, 80%, 100% { transform: scale(0); }
      40% { transform: scale(1); }
    }
  `;

  // 添加機器人大頭貼的樣式組件
  const AssistantAvatar = styled.div`
    position: absolute;
    left: -40px;
    bottom: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${props => props.theme.background.paper};
    border: 1px solid ${props => props.theme.border.main};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${props => props.theme.shadows.sm};
    overflow: hidden;
    z-index: 1; // 確保大頭貼在名上方
  `;

  // 添機器人圖標件
  const RobotIcon = () => (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M12 2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2 2 2 0 0 1-2-2V4a2 2 0 0 1 2-2z"/>
      <rect x="4" y="8" width="16" height="12" rx="2"/>
      <path d="M9 14h.01M15 14h.01"/>
      <path d="M9 14a1 1 0 1 0 0-2 1 1 0 0 0 2z"/>
      <path d="M15 14a1 1 0 1 0 0-2 1 1 0 0 0 2z"/>
    </svg>
  );

  // 修改 ModelLabel 樣式，移除透明度動畫，改為永久顯示
  const ModelLabel = styled.div`
    position: absolute;
    right: 0.5rem;
    bottom: -1.75rem;
    font-size: 0.75rem;
    color: ${props => props.theme.text.secondary};
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.5rem;
    background: ${props => props.theme.background.paper}95;
    border-radius: 4px;
    backdrop-filter: blur(8px);
    border: 1px solid ${props => props.theme.border.main}20;
    white-space: nowrap;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    opacity: 0.85; // 改為固定透明度
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      opacity: 1;
      background: ${props => props.theme.background.paper};
      border-color: ${props => props.theme.border.main}40;
    }

    svg {
      width: 12px;
      height: 12px;
      color: ${props => props.theme.primary.main};
      transition: transform 0.2s ease;
    }

    &:hover svg {
      transform: rotate(180deg);
    }
  `;

  // 修改 BetaTag 組件的樣式
  const BetaTag = styled.span`
    font-size: 1rem;
    font-weight: 600;
    color: ${props => props.theme.primary.main};
    padding: 0.25rem 0.75rem;
    border-radius: 6px;
    background: ${props => props.theme.primary.main}15;
    white-space: nowrap;
    border: 1px solid ${props => props.theme.primary.main}30;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: default;

    &::after {
      content: 'Advanced AI Chatbot 先進的對話系統';
      font-size: 0.875rem;
      padding: 0.125rem 0.375rem;
      color: ${props => props.theme.text.secondary};
      font-weight: normal;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  `;

  // 修改 VersionTag 組件的樣式
  const VersionTag = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    color: ${props => props.theme.text.primary};
    padding: 0.5rem 0.75rem;
    background: ${props => props.theme.background.paper};
    border: 1px solid ${props => props.theme.border.main};
    border-radius: 12px;
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: 0.75rem;
    transition: all 0.2s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: default;

    /* 添加漸變背景 */
    background: linear-gradient(
      135deg,
      ${props => props.theme.background.paper},
      ${props => props.theme.background.default}
    );

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border-color: ${props => props.theme.primary.main};
    }

    svg {
      width: 14px;
      height: 14px;
      color: ${props => props.theme.primary.main};
    }
  `;

  // 修改版本號圖標
  const VersionIcon = () => (
    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
    </svg>
  );

  // 添日期分隔線的樣式
  const DateDivider = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
    opacity: 0.7;
    user-select: none;

    &::before,
    &::after {
      content: '';
      flex: 1;
      height: 1px;
      background: ${props => props.theme.border.main};
    }
  `;

  const DateLabel = styled.span`
    font-size: 0.75rem;
    color: ${props => props.theme.text.secondary};
    padding: 0.25rem 0.75rem;
    background: ${props => props.theme.background.paper}80;
    border-radius: 12px;
    backdrop-filter: blur(4px);
    border: 1px solid ${props => props.theme.border.main}40;
  `;

  // 添加格式化日期的函數
  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return '今天';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return '昨天';
    } else {
      return date.toLocaleDateString('zh-TW', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
  };

  // 添加標題編輯相關的處理函數
  const handleTitleClick = () => {
    setIsEditingTitle(true);
    setTempTitle(currentTitle);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempTitle(e.target.value);
  };

  const handleTitleSubmit = () => {
    if (tempTitle.trim() && sessionId) {
      const newTitle = tempTitle.trim();
      setCurrentTitle(newTitle);
      setIsEditingTitle(false);
      
      // 更新存儲並通知父組件
      const session = StorageService.getSession(sessionId);
      if (session) {
        const updatedSession = {
          ...session,
          title: newTitle,
          updatedAt: Date.now()
        };
        
        // 保存到本地存儲
        StorageService.saveChatSession(updatedSession);
        
        // 通知父組件更新側邊欄
        if (onTitleChange) {
          onTitleChange(sessionId, newTitle);
        }
      }
    } else {
      // 如果標題為空，恢復原標題
      setTempTitle(currentTitle);
      setIsEditingTitle(false);
    }
  };

  // 添加 renderMessageContent 函數定義
  const renderMessageContent = (content: string, isDarkMode: boolean) => {
    // 修改正則表達式以支援多行方括號公式
    content = content.replace(
      /\[\s*\n*([\s\S]*?)\n*\s*\]/g,  // 使用 [\s\S] 來匹配包括換行在內的所有字符
      (_, formula) => `$$${formula.trim()}$$`  // 使用 $$ 來表示區塊公式
    );

    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          code: ({ className, children }) => {
            // 處理 LaTeX 區塊
            if (className === 'language-latex' || className === 'language-math') {
              const mathContent = String(children).replace(/\n$/, '');
              return (
                <LatexContainer>
                  <BlockMath>{mathContent}</BlockMath>
                </LatexContainer>
              );
            }
            
            // 處理一般程式碼區塊
            const match = /language-(\w+)/.exec(className || '');
            if (match && !className?.includes('inline')) {
              const language = match[1];
              const codeContent = String(children).replace(/\n$/, '');
              
              return (
                <CodeBlockComponent
                  language={language}
                  code={codeContent}
                  isDarkMode={isDarkMode}
                />
              );
            }
            return <code className={className}>{children}</code>;
          },
          // 處理行內 LaTeX
          p: ({ children }) => {
            if (typeof children === 'string') {
              // 使用正則表達式匹配 $...$ 和 $$...$$ 格式的 LaTeX
              const parts = children.split(/(\$\$[^$]+\$\$|\$[^$]+\$)/g);
              return (
                <p>
                  {parts.map((part, index) => {
                    if (part.startsWith('$$') && part.endsWith('$$')) {
                      // 處理區塊公式
                      const formula = part.slice(2, -2).trim();
                      return (
                        <LatexContainer key={index}>
                          <BlockMath>{formula}</BlockMath>
                        </LatexContainer>
                      );
                    } else if (part.startsWith('$') && part.endsWith('$')) {
                      // 處理行內公式
                      const formula = part.slice(1, -1).trim();
                      return (
                        <InlineMath key={index}>
                          {formula}
                        </InlineMath>
                      );
                    }
                    return part;
                  })}
                </p>
              );
            }
            return <p>{children}</p>;
          }
        }}
      >
        {content}
      </ReactMarkdown>
    );
  };

  // 在組件初始化時設置默認值
  useEffect(() => {
    if (settings.enableWebSearch === undefined) {
      setSettings({
        ...settings,
        enableWebSearch: false
      });
    }
  }, []);

  return (
    <ChatContainer>
      <ChatHeader>
        <HeaderTitle isSidebarCollapsed={isSidebarCollapsed}>
          <TitleText>
            {isEditingTitle ? (
              <EditableTitleInput
                value={tempTitle}
                onChange={handleTitleChange}
                onBlur={handleTitleSubmit}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleTitleSubmit();
                  }
                }}
                placeholder="輸入對話標題..."
                autoFocus
              />
            ) : (
              <MainTitle 
                onClick={handleTitleClick}
                title="點擊編輯標題"
              >
                {currentTitle}
              </MainTitle>
            )}
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <BetaTag>Stima Playground</BetaTag>
              <VersionTag>
                <VersionIcon />
                Version 0.0.40
              </VersionTag>
            </div>
          </TitleText>
        </HeaderTitle>
      </ChatHeader>
      
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      <MessageList ref={messageListRef}>
        {messages.map((message, index) => {
          // 檢查是否需要添加日期分隔線
          const showDateDivider = index > 0 && (
            new Date(message.timestamp || 0).toDateString() !==
            new Date(messages[index - 1].timestamp || 0).toDateString()
          );

          return (
            <React.Fragment key={index}>
              {showDateDivider && (
                <DateDivider>
                  <DateLabel>
                    {formatDate(message.timestamp || 0)}
                  </DateLabel>
                </DateDivider>
              )}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <MessageItemWithRef 
                  role={message.role}
                  ref={index === messages.length - 1 ? lastMessageRef : null}
                >
                  {message.role === 'assistant' && (
                    <>
                      <AssistantAvatar>
                        <img 
                          src="/images/logo.png" 
                          alt="Stima Logo" 
                          style={{ 
                            width: '100%', 
                            height: '100%',
                            objectFit: 'cover'
                          }} 
                        />
                      </AssistantAvatar>
                      <ModelLabel>
                        <ModelIcon />
                        {getCurrentModelName()}
                      </ModelLabel>
                    </>
                  )}
                  {/* 使用 renderMessageContent 替換原來的內容渲染 */}
                  {renderMessageContent(message.content, isDarkMode)}
                  
                  {/* 保持原有的操作按鈕 */}
                  <MessageActions>
                    <ActionButton 
                      onClick={() => copyMessage(message)}
                      title="複製"
                    >
                      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                      </svg>
                    </ActionButton>
                    <ActionButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        startEditing(message);
                      }}
                      title="編輯"
                    >
                      <EditIcon />
                    </ActionButton>
                    <ActionButton 
                      onClick={() => resendMessage(index)}
                      title="重新發送"
                    >
                      <ResendIcon />
                    </ActionButton>
                  </MessageActions>
                </MessageItemWithRef>
                {/* 時間戳記移到這裡 */}
                <TimeStamp role={message.role}>
                  <ClockIcon />
                  {formatTime(message.timestamp || Date.now())}
                </TimeStamp>
              </div>
            </React.Fragment>
          );
        })}
        {isLoading && <TypingIndicator>
          <TypingDot />
          <TypingDot />
          <TypingDot />
        </TypingIndicator>}
      </MessageList>

      <InputArea>
        <ButtonGroup>
          <ModelButton onClick={() => setShowModelPopup(!showModelPopup)}>
            <ModelIcon />
            {getCurrentModelName()}
          </ModelButton>
          <PrePromptButton 
            onClick={() => {
              setTempPrePrompt(settings.prePrompt || '');
              setShowPrePromptPopup(true);
            }}
            title="設定預設提示詞"
          >
            <PrePromptIcon />
            預設提示詞
          </PrePromptButton>
          {/* 添加網路搜索開關按鈕 */}
          <WebSearchButton 
            isActive={settings.enableWebSearch ?? false}
            onClick={() => {
              setSettings({
                ...settings,
                enableWebSearch: !(settings.enableWebSearch ?? false)
              });
            }}
            title={settings.enableWebSearch ? "已啟用網路搜索" : "點擊啟用網路搜索"}
          >
            <SearchIcon />
            {settings.enableWebSearch ? "已開啟網路搜索" : "網路搜索"}
          </WebSearchButton>
        </ButtonGroup>

        <Overlay 
          isVisible={showModelPopup || showPrePromptPopup} 
          onClick={() => {
            setShowModelPopup(false);
            setShowPrePromptPopup(false);
          }}
        />

        <ModelPopup isOpen={showModelPopup}>
          {SUPPORTED_PROVIDERS.map(provider => (
            <ProviderSection key={provider.name}>
              <ProviderHeader>
                {ProviderIcons[provider.name.toLowerCase()] && 
                  React.createElement(ProviderIcons[provider.name.toLowerCase()], { 
                    size: 20,
                    key: provider.name 
                  })}
                {provider.name}
              </ProviderHeader>
              <ModelList>
                {provider.models.map(model => (
                  <ModelOption
                    key={model.id}
                    isSelected={settings.currentModel === model.id}
                    onClick={() => {
                      setSettings({
                        ...settings,
                        provider: provider.name.toLowerCase(),
                        currentModel: model.id
                      });
                      setShowModelPopup(false);
                    }}
                  >
                    <ModelName>{model.name}</ModelName>
                  </ModelOption>
                ))}
              </ModelList>
            </ProviderSection>
          ))}
        </ModelPopup>

        <PrePromptPopup isOpen={showPrePromptPopup}>
          <PopupHeader>
            <PopupTitle>設定預設提示詞</PopupTitle>
          </PopupHeader>
          <PrePromptDescription>
            此提示詞會在每次對話開始時自動添加，用於設定 AI 的回答方式。
          </PrePromptDescription>
          <PrePromptTextarea
            value={tempPrePrompt}
            onChange={(e) => setTempPrePrompt(e.target.value)}
            placeholder="例如：你是一位專業的理財顧問, 請用清楚易懂的方式解釋專業術語。"
          />
          <ButtonContainer>
            <SecondaryButton onClick={() => setShowPrePromptPopup(false)}>
              取消
            </SecondaryButton>
            <Button onClick={handleSavePrePrompt}>
              保存
            </Button>
          </ButtonContainer>
        </PrePromptPopup>

        <InputWrapper>
          <div style={{ position: 'relative' }}>
            <AttachmentButton
              ref={attachButtonRef}
              onClick={() => setShowAttachMenu(!showAttachMenu)}
              title="添加件"
            >
              <AttachmentIcon />
            </AttachmentButton>
            <AttachmentMenu 
              ref={attachMenuRef}
              isOpen={showAttachMenu}
            >
              <AttachmentOption 
                onClick={() => {
                  fileInputRef.current?.click();
                  setShowAttachMenu(false);
                }}
              >
                <FileIcon /> 上傳文件(目前尚未支援)
              </AttachmentOption>
              <AttachmentOption 
                onClick={() => {
                  imageInputRef.current?.click();
                  setShowAttachMenu(false);
                }}
              >
                <ImageIcon /> 上傳圖片(目前尚未支援)
              </AttachmentOption>
              <AttachmentOption 
                onClick={() => {
                  codeInputRef.current?.click();
                  setShowAttachMenu(false);
                }}
              >
                <CodeIcon /> 上傳程式碼文件
              </AttachmentOption>
            </AttachmentMenu>
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => handleAttachment(e.target.files, 'file')}
            />
            <input
              ref={imageInputRef}
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => handleAttachment(e.target.files, 'image')}
            />
            <input
              ref={codeInputRef}
              type="file"
              accept=".js,.jsx,.ts,.tsx,.py,.java,.cpp,.c,.cs,.rb,.php,.html,.css,.json,.yaml,.md"
              style={{ display: 'none' }}
              onChange={(e) => handleAttachment(e.target.files, 'code')}
            />
          </div>
          <Input
            value={input}
            onChange={handleInputChange}
            onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage(input);
              }
            }}
            placeholder="輸入訊息..."
          />
          {/* 添加清除按鈕 */}
          <ClearInputButton
            onClick={() => setInput('')}
            visible={input.length > 0}
            title="清除訊息"
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <circle cx="12" cy="12" r="10"/>
              <line x1="15" y1="9" x2="9" y2="15"/>
              <line x1="9" y1="9" x2="15" y2="15"/>
            </svg>
          </ClearInputButton>
          <SendButton 
            onClick={() => handleSendMessage(input)}
            disabled={!input.trim() && !attachments.length}
          >
            <SendIcon />
          </SendButton>
        </InputWrapper>
        {attachments.length > 0 && (
          <div>
            {attachments.map((attachment, index) => (
              attachment.type === 'image' ? (
                <ImagePreview key={index}>
                  <img src={attachment.preview} alt={attachment.file.name} />
                  <ImagePreviewOverlay>
                    <ImagePreviewActions>
                      <ActionButton 
                        onClick={() => removeAttachment(index)}
                        title="移除圖片"
                      >
                        <DeleteIcon />
                      </ActionButton>
                    </ImagePreviewActions>
                  </ImagePreviewOverlay>
                </ImagePreview>
              ) : (
                <AttachmentPreview key={index}>
                  {attachment.type === 'code' ? <CodeIcon /> : <FileIcon />}
                  {attachment.file.name}
                  <DeleteButton onClick={() => removeAttachment(index)}>
                    <ClearIcon />
                  </DeleteButton>
                </AttachmentPreview>
              )
            ))}
          </div>
        )}
      </InputArea>

      {/* 添加編輯彈出視窗 */}
      {editingMessageId && (
        <>
          <Overlay 
            isVisible={true} 
            onClick={() => {
              setEditingMessageId(null);
              setEditedContent('');
              setEditHistory([]);
              setCurrentHistoryIndex(-1);
            }}
          />
          <EditPopup isOpen={true}>
            <EditPopupHeader>
              <EditPopupTitle>輯訊息</EditPopupTitle>
              <KeyboardShortcuts>
                <div>
                  <ShortcutKey>Ctrl</ShortcutKey> + <ShortcutKey>Enter</ShortcutKey>保存並發送
                </div>
                <div>
                  <ShortcutKey>Esc</ShortcutKey> 取消
                </div>
              </KeyboardShortcuts>
            </EditPopupHeader>
            
            <EditPopupContent>
              <EditPopupTextarea
                className="edit-popup-textarea"
                value={editedContent}
                onChange={handleEditChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault();
                    const messageIndex = messages.findIndex(
                      m => m.timestamp?.toString() === editingMessageId
                    );
                    if (messageIndex !== -1) {
                      saveEdit(messageIndex);
                      resendMessage(messageIndex);
                    }
                  } else if (e.key === 'Escape') {
                    setEditingMessageId(null);
                    setEditedContent('');
                    setEditHistory([]);
                    setCurrentHistoryIndex(-1);
                  } else if (e.key === 'z' && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault();
                    undo();
                  } else if (e.key === 'y' && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault();
                    redo();
                  }
                }}
                autoFocus
              />
            </EditPopupContent>

            <EditPopupFooter>
              <EditStats>
                <div>字數：{editedContent.length}</div>
                <div>行數：{editedContent.split('\n').length}</div>
                <div>
                  <EditHistoryButton 
                    onClick={undo} 
                    disabled={currentHistoryIndex <= 0}
                    title="復原 (Ctrl+Z)"
                  >
                    <UndoIcon />
                  </EditHistoryButton>
                  <EditHistoryButton 
                    onClick={redo} 
                    disabled={currentHistoryIndex >= editHistory.length - 1}
                    title="做 (Ctrl+Y)"
                  >
                    <RedoIcon />
                  </EditHistoryButton>
                </div>
              </EditStats>
              
              <EditPopupActions>
                <SecondaryButton
                  onClick={() => {
                    setEditingMessageId(null);
                    setEditedContent('');
                    setEditHistory([]);
                    setCurrentHistoryIndex(-1);
                  }}
                >
                  取消
                </SecondaryButton>
                <Button
                  onClick={() => {
                    const messageIndex = messages.findIndex(
                      m => m.timestamp?.toString() === editingMessageId
                    );
                    if (messageIndex !== -1) {
                      saveEdit(messageIndex);
                      resendMessage(messageIndex);
                    }
                  }}
                >
                  保存並重新發送
                </Button>
              </EditPopupActions>
            </EditPopupFooter>
          </EditPopup>
        </>
      )}
    </ChatContainer>
  );
};

// 添加的樣式組件
const EditableMessage = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  background: ${props => props.theme.background.default};
  color: ${props => props.theme.text.primary};
  font-size: 0.9375rem;
  line-height: 1.4;
  resize: vertical;
  margin: 0.5rem 0;
  font-family: inherit;
  z-index: 10;
`;

const EditContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 11;
`;

const MessageActions = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.25rem;
  opacity: 0;
  transform: translateY(5px);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: ${props => props.theme.background.paper}95;
  padding: 0.35rem;
  border-radius: 8px;
  backdrop-filter: blur(8px);
  border: 1px solid ${props => props.theme.border.main}30;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;

  ${MessageItem}:hover & {
    opacity: 1;
    transform: translateY(0);
  }

  // 添加動畫果
  & > button {
    transform: scale(0.9);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const ActionButton = styled.button`
  width: 28px;
  height: 28px;
  padding: 0;
  background: transparent;
  border: none;
  color: ${props => props.theme.text.secondary};
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;

  &::before {
    content: attr(title);
    position: absolute;
    top: -2.5rem;
    left: 50%;
    transform: translateX(-50%) translateY(5px);
    background: ${props => props.theme.background.paper};
    color: ${props => props.theme.text.primary};
    padding: 0.4rem 0.8rem;
    border-radius: 6px;
    font-size: 0.75rem;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border: 1px solid ${props => props.theme.border.main}30;
    pointer-events: none;
  }

  &:hover {
    background: ${props => props.theme.background.paper};
    color: ${props => props.theme.primary.main};
    
    &::before {
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) translateY(0);
    }
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    width: 16px;
    height: 16px;
    transition: transform 0.2s ease;
  }

  // 為每個按鈕添加獨特的懸停效果
  &[title="複製"]:hover svg {
    transform: translateY(-2px);
  }

  &[title="編輯"]:hover svg {
    transform: rotate(-10deg);
  }

  &[title="重新發送"]:hover svg {
    transform: rotate(45deg);
  }
`;

// 添編相關的樣式組件
const EditActions = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;

const EditButton = styled.button<{ variant?: 'primary' | 'secondary' }>`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  
  ${props => props.variant === 'secondary' ? `
    background: ${props.theme.secondary.main};
    color: ${props.theme.text.primary};
    
    &:hover {
      background: ${props.theme.secondary.dark};
    }
  ` : `
    background: ${props.theme.primary.main};
    color: ${props.theme.primary.contrastText};
    
    &:hover {
      background: ${props.theme.primary.dark};
    }
  `}
`;

// 添加事件處理器的類型定義
interface TextareaChangeEvent extends React.ChangeEvent<HTMLTextAreaElement> {}
interface TextareaKeyboardEvent extends React.KeyboardEvent<HTMLTextAreaElement> {}

// 添加右側按鈕組樣式
const HeaderActions = styled.div`
  display: flex;
  gap: 1rem; // 增加按鈕之間的間距
  align-items: center;
  position: relative;
  z-index: 101;
  margin-right: 1rem; // 增加右側邊距
`;

// 添加導出按鈕樣式
const ExportButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  color: ${props => props.theme.text.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: ${props => props.theme.shadows.sm};
  position: relative;
  z-index: 1001;  // 保按鈕可

  &:hover {
    background: ${props => props.theme.secondary.hover};
    transform: scale(1.05);
    box-shadow: ${props => props.theme.shadows.md};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

// 添加或修改相關樣式
const HeaderButton = styled.button`
  width: 44px; // 稍微增加按鈕大小
  height: 44px;
  border-radius: 50%;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  color: ${props => props.theme.text.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: ${props => props.theme.shadows.sm};
  position: relative;
  padding: 0; // 確保內容居中
  margin: 0 0.25rem; // 添加水平邊距

  &:hover {
    background: ${props => props.theme.secondary.hover};
    transform: scale(1.05);
    box-shadow: ${props => props.theme.shadows.md};
  }

  &.starred {
    color: ${props => props.theme.status.warning};
    background: ${props => props.theme.status.warning}10;
  }

  svg {
    width: 22px; // 稍微增加圖標大小
    height: 22px;
    margin: auto; // 確保圖標居中
  }
`;

// 添加刪除圖標組件（在其他圖標組件附近添加）
const DeleteIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
  </svg>
);

// 添加新的圖標組件
const SettingsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="3" />
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
  </svg>
);

const ThemeIcon = ({ isDarkMode }: { isDarkMode: boolean }) => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    {isDarkMode ? (
      // 月亮圖標
      <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
    ) : (
      // 太陽圖標
      <>
        <circle cx="12" cy="12" r="5" />
        <line x1="12" y1="1" x2="12" y2="3" />
        <line x1="12" y1="21" x2="12" y2="23" />
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
        <line x1="1" y1="12" x2="3" y2="12" />
        <line x1="21" y1="12" x2="23" y2="12" />
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
      </>
    )}
  </svg>
);

// 添加缺失的樣式組件定義
const AttachmentButton = styled.button`
  position: relative;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: ${props => props.theme.text.secondary};
  opacity: 0.6;
  transition: all 0.2s ease;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
    background: ${props => props.theme.secondary.hover};
  }
`;

const AttachmentMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: calc(100% + 8px);
  bottom: 0;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  padding: 0.5rem;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  gap: 0.25rem;
  box-shadow: ${props => props.theme.shadows.md};
  z-index: 1100;
  min-width: 150px;
`;

const AttachmentOption = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  background: none;
  border: none;
  width: 100%;
  color: ${props => props.theme.text.primary};
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  white-space: nowrap;

  &:hover {
    background: ${props => props.theme.secondary.hover};
    transform: translateX(4px);
  }

  svg {
    opacity: 0.7;
    flex-shrink: 0;
  }

  &:hover svg {
    opacity: 1;
  }
`;

const AttachmentPreview = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// 添加編輯彈出視窗的樣式組件
const EditPopup = styled(ModelPopup)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  height: 80vh; // 定固定高
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.background.paper};
  border-radius: 12px;
  box-shadow: ${props => props.theme.shadows.lg};
  z-index: 1200;
  
  animation: fadeIn 0.2s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.98);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;

const EditPopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${props => props.theme.border.main};
  background: ${props => props.theme.background.paper};
  flex-shrink: 0; // 防header被壓縮
`;

const EditPopupContent = styled.div`
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto; // 只有內容區域可以滾動
  display: flex;
  flex-direction: column;
`;

const EditPopupFooter = styled.div`
  padding: 1rem 1.5rem;
  border-top: 1px solid ${props => props.theme.border.main};
  background: ${props => props.theme.background.paper};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0; // 防止footer被壓縮

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const EditPopupTextarea = styled.textarea`
  width: 100%;
  height: 100%; // 讓文本區域填滿可用空間
  padding: 1rem;
  background: ${props => props.theme.background.default};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 8px;
  color: ${props => props.theme.text.primary};
  font-size: 1rem;
  line-height: 1.5;
  resize: none; // 移除resize功能，因為現在是自適應高度
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary.main};
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }
`;

const EditStats = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${props => props.theme.text.secondary};
  font-size: 0.875rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const KeyboardShortcuts = styled.div`
  display: flex;
  gap: 1rem;
  color: ${props => props.theme.text.secondary};
  font-size: 0.875rem;
`;

const ShortcutKey = styled.kbd`
  padding: 0.25rem 0.5rem;
  background: ${props => props.theme.background.default};
  border: 1px solid ${props => props.theme.border.main};
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.75rem;
`;

const EditHistoryButton = styled.button<{ disabled?: boolean }>`
  padding: 0.5rem;
  background: none;
  border: none;
  color: ${props => props.disabled ? props.theme.text.disabled : props.theme.text.secondary};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  
  &:hover:not(:disabled) {
    color: ${props => props.theme.primary.main};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const UndoIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M12 19V5M12 5l-3 3 3 3M12 5l3 3-3 3" />
  </svg>
);

const RedoIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M12 5v14M12 14l-3-3 3-3M12 14l3-3-3-3" />
  </svg>
);

// 在其他樣式組件定義的地方添加這個
const EditPopupTitle = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  color: ${props => props.theme.text.primary};
  font-weight: 600;
`;

// 添加 EditPopupActions 的樣式組件定義
const EditPopupActions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0; // 防止按鈕被壓縮
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

// 添加可編的樣式組件
const EditableTitleInput = styled.input`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${props => props.theme.text.primary};
  background: ${props => props.theme.background.default};
  border: 2px solid ${props => props.theme.primary.main};
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  width: 100%;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.primary.main}20;
  }
`;

// 添加間戳記的樣式
const TimeStamp = styled.div<{ role: string }>`
  position: relative; // 改為相對定位
  margin-top: 2px; // 減上間距
  ${props => props.role === 'user' ? 'margin-left: auto;' : 'margin-left: 1rem;'}; // 對齊消息框
  font-size: 0.75rem;
  color: ${props => props.role === 'user' 
    ? 'rgba(255, 255, 255, 0.7)' 
    : props.theme.text.secondary};
  opacity: 0.5;
  transform: translateY(0);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 2px 6px;
  border-radius: 4px;
  width: fit-content; // 寬度適應內容
  margin-right: ${props => props.role === 'user' ? '1rem' : 'auto'}; // 對齊消息框

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
  }

  svg {
    width: 12px;
    height: 12px;
    opacity: 0.7;
  }
`;

// 添加時鐘圖
const ClockIcon = () => (
  <svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="10" />
    <path d="M12 6v6l4 2" />
  </svg>
);

// 格式化時間的函數
const formatTime = (timestamp: number) => {
  const date = new Date(timestamp);
  const now = new Date();
  const isToday = date.toDateString() === now.toDateString();
  
  const timeStr = date.toLocaleTimeString('zh-TW', { 
    hour: '2-digit', 
    minute: '2-digit'
  });
  
  if (isToday) {
    return timeStr;
  }
  
  return `${date.toLocaleDateString('zh-TW')} ${timeStr}`;
};

// 添加 PrePromptDescription 樣式組件
const PrePromptDescription = styled.div`
  color: ${props => props.theme.text.primary}; // 確保在暗色模式下文字為白色
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 1rem 0;
  padding: 0.5rem;
  background: ${props => props.theme.background.paper}40;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.border.main}30;
  
  /* 添加微妙的懸停效果 */
  transition: all 0.2s ease;
  &:hover {
    background: ${props => props.theme.background.paper}60;
    border-color: ${props => props.theme.border.main}50;
  }
`;

// 添加 ClearInputButton 的 props 類型定義
interface ClearInputButtonProps {
  visible?: boolean;
}

// 修改 ClearInputButton 的樣式組件定義
const ClearInputButton = styled.button<ClearInputButtonProps>`
  position: absolute;
  right: 4rem; // 放在發送按鈕左側
  top: 50%;
  transform: translateY(-50%);
  width: 32px; // 增加按鈕大小
  height: 32px; // 增���按鈕大小
  padding: 0;
  background: ${props => props.theme.background.paper}80;
  border: 1px solid ${props => props.theme.border.main}30;
  color: ${props => props.theme.text.secondary};
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  backdrop-filter: blur(4px);

  svg {
    width: 20px; // 增加圖標大小
    height: 20px; // 增加圖標大小
  }

  &:hover {
    background: ${props => props.theme.secondary.hover};
    color: ${props => props.theme.status.error};
    border-color: ${props => props.theme.status.error}50;
    transform: translateY(-50%) scale(1.05);
  }

  &:active {
    transform: translateY(-50%) scale(0.95);
  }

  /* 當輸入框有內容時顯示 */
  ${props => props.visible && `
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  `}
`;

// 將 LatexContainer 移到組件外部
const LatexContainer = styled.div`
  margin: 1rem 0;
  overflow-x: auto;
  text-align: center;
  
  .katex-display {
    margin: 0;
    padding: 1rem;
    background: ${props => props.theme.background.paper}80;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.border.main}30;
  }

  .katex {
    font-size: 1.1em;
  }

  .katex-html {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 2px 0;
  }
`;

// 添加行內公式的樣式
const InlineMathContainer = styled.span`
  .katex {
    font-size: 1.1em;
  }
`;

// 添加新的圖片預覽樣式組件
const ImagePreview = styled.div`
  position: relative;
  margin: 0.5rem 0;
  max-width: 300px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.border.main};
  background: ${props => props.theme.background.paper};
  
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const ImagePreviewOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

const ImagePreviewActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const RefreshIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M21 2v6h-6M3 12a9 9 0 0 1 15-6.7L21 8M3 22v-6h6M21 12a9 9 0 0 1-15 6.7L3 16" />
  </svg>
);

// 修改 WebSearchButton 的類型定義
const WebSearchButton = styled(PrePromptButton)<{ isActive?: boolean }>`
  background: ${props => (props.isActive ?? false) ? props.theme.primary.main + '20' : props.theme.background.paper};
  border-color: ${props => (props.isActive ?? false) ? props.theme.primary.main : props.theme.border.main};
  color: ${props => (props.isActive ?? false) ? props.theme.primary.main : props.theme.text.primary};

  &:hover {
    background: ${props => (props.isActive ?? false) ? props.theme.primary.main + '30' : props.theme.secondary.hover};
    border-color: ${props => props.theme.primary.main};
  }

  svg {
    color: ${props => (props.isActive ?? false) ? props.theme.primary.main : 'inherit'};
  }
`;

// 添加搜索圖標組件
const SearchIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="11" cy="11" r="8" />
    <line x1="21" y1="21" x2="16.65" y2="16.65" />
    <path d="M11 8a3 3 0 0 1 0 6" />
  </svg>
);

export default ChatArea; 