import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import Sidebar from './components/Sidebar';
import ChatArea from './components/ChatArea';
import Settings from './components/Settings';
import { GlobalStyle } from './styles/GlobalStyle';
import { SUPPORTED_PROVIDERS } from './types/models';
import styled from 'styled-components';
import { StorageService } from './services/storage';
import { parseSettingsFromUrl } from './utils/urlParser';
import type { Settings as SettingsType } from './types';
import { ChatSession } from './types';
import { verifySessionToken } from './utils/auth';
import { auth } from './config/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import type { User } from './types/auth';

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainContent = styled.main`
  flex: 1;
  overflow: hidden;
  position: relative;
  
  @media (max-width: 768px) {
    height: calc(100vh - 60px); // 為底部導航預留空間
  }
`;

const SettingsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="3"/>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
  </svg>
);

const SunIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="5"/>
    <line x1="12" y1="1" x2="12" y2="3"/>
    <line x1="12" y1="21" x2="12" y2="23"/>
    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"/>
    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"/>
    <line x1="1" y1="12" x2="3" y2="12"/>
    <line x1="21" y1="12" x2="23" y2="12"/>
    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"/>
    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"/>
  </svg>
);

const MoonIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"/>
  </svg>
);

const ButtonGroup = styled.div`
  position: fixed;
  right: 1rem;
  top: 1rem;
  display: flex;
  gap: 0.5rem;
  z-index: 1000;

  @media (max-width: 768px) {
    top: auto;
    bottom: 1rem;
  }
`;

const IconButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.theme.background.paper};
  border: 1px solid ${props => props.theme.border.main};
  color: ${props => props.theme.text.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: ${props => props.theme.shadows.sm};

  &:hover {
    background: ${props => props.theme.secondary.hover};
    transform: scale(1.05);
    box-shadow: ${props => props.theme.shadows.md};
  }

  svg {
    transition: transform 0.2s ease;
  }

  &:hover svg {
    transform: rotate(15deg);
  }
`;

const MobileNav = styled.nav`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: ${props => props.theme.background.paper};
  border-top: 1px solid ${props => props.theme.border.main};
  padding: 0.5rem;
  z-index: 1000;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

const MobileNavButton = styled.button`
  background: none;
  border: none;
  padding: 0.5rem;
  color: ${props => props.theme.text.secondary};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.primary.main};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const ExportIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="7 10 12 15 17 10" />
    <line x1="12" y1="15" x2="12" y2="3" />
  </svg>
);

const RefreshIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M21 2v6h-6M3 12a9 9 0 0 1 15-6.7L21 8M3 22v-6h6M21 12a9 9 0 0 1-15 6.7L3 16" />
  </svg>
);

const generateId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme === 'dark' : false;
  });
  const [currentSession, setCurrentSession] = useState<string | null>(null);
  const [settings, setSettings] = useState<SettingsType>(() => {
    const urlSettings = parseSettingsFromUrl();
    
    if (urlSettings?.key || urlSettings?.url) {
      const savedSettings = StorageService.getSettings() || {
        baseUrl: '',
        apiKey: '',
        currentModel: SUPPORTED_PROVIDERS[0].models[0].id,
        provider: SUPPORTED_PROVIDERS[0].name.toLowerCase(),
        theme: 'light' as 'light' | 'dark',
        prePrompt: '',
        compressionModel: 'gpt-4o-mini',
        compressionRounds: 5
      };

      return {
        ...savedSettings,
        apiKey: urlSettings.key || savedSettings.apiKey,
        baseUrl: urlSettings.url || savedSettings.baseUrl
      };
    }

    const savedSettings = StorageService.getSettings();
    return savedSettings || {
      baseUrl: '',
      apiKey: '',
      currentModel: SUPPORTED_PROVIDERS[0].models[0].id,
      provider: SUPPORTED_PROVIDERS[0].name.toLowerCase(),
      theme: 'light' as 'light' | 'dark',
      prePrompt: '',
      compressionModel: 'gpt-4o-mini',
      compressionRounds: 5
    };
  });

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showSidebar, setShowSidebar] = useState(window.innerWidth > 768);

  const [sessions, setSessions] = useState<ChatSession[]>(() => {
    const sessionsRecord = StorageService.getAllSessions();
    return Object.values(sessionsRecord).sort((a, b) => b.updatedAt - a.updatedAt);
  });

  const [user, setUser] = useState<User | null>(null);

  const handleTitleChange = (sessionId: string, newTitle: string) => {
    // 更新本地存儲
    const session = StorageService.getSession(sessionId);
    if (session) {
      const updatedSession = {
        ...session,
        title: newTitle,
        updatedAt: Date.now()
      };
      StorageService.saveChatSession(updatedSession);

      // 更新 sessions state 以觸發重新渲染
      setSessions(prev => {
        const updatedSessions = prev.map(s => 
          s.id === sessionId 
            ? { ...s, title: newTitle, updatedAt: Date.now() }
            : s
        );
        return updatedSessions.sort((a, b) => b.updatedAt - a.updatedAt);
      });
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const urlSettings = parseSettingsFromUrl();
      if (urlSettings?.key || urlSettings?.url) {
        setSettings((prev: SettingsType) => ({
          ...prev,
          apiKey: urlSettings.key || prev.apiKey,
          baseUrl: urlSettings.url || prev.baseUrl
        }));
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  useEffect(() => {
    StorageService.saveSettings(settings);
  }, [settings]);

  useEffect(() => {
    if (window.location.hash.includes('settings=')) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!currentSession) {
      const sessionsRecord = StorageService.getAllSessions();
      const sessionsArray = Object.values(sessionsRecord).sort((a, b) => b.updatedAt - a.updatedAt);
      
      if (sessionsArray.length > 0) {
        setCurrentSession(sessionsArray[0].id);
      } else {
        const newSessionId = generateId();
        const newSession: ChatSession = {
          id: newSessionId,
          title: '新對話',
          messages: [],
          createdAt: Date.now(),
          updatedAt: Date.now(),
          isStarred: false,
          summary: ''
        };
        
        StorageService.saveChatSession(newSession);
        setCurrentSession(newSessionId);
      }
    }
  }, []);

  const createNewSession = () => {
    const newSessionId = generateId();
    const newSession: ChatSession = {
      id: newSessionId,
      title: '新對話',
      messages: [],
      createdAt: Date.now(),
      updatedAt: Date.now(),
      isStarred: false,
      summary: ''
    };
    
    // 保存到本地存儲
    StorageService.saveChatSession(newSession);
    
    // 更新 sessions state
    setSessions(prev => {
      const updatedSessions = [...prev, newSession];
      return updatedSessions.sort((a, b) => b.updatedAt - a.updatedAt);
    });
    
    // 設置當前會話
    setCurrentSession(newSessionId);
  };

  const handleExport = () => {
    try {
      if (!currentSession) {
        console.error('無法導出：沒有活動的對話');
        return;
      }
      
      const session = StorageService.getSession(currentSession);
      if (!session) {
        console.error('無法導出：找不到對話數據');
        return;
      }
      
      const exportData = {
        id: session.id,
        title: session.title || '未命名對話',
        messages: session.messages,
        createdAt: session.createdAt,
        updatedAt: session.updatedAt,
        isStarred: session.isStarred,
        summary: session.summary,
        exportedAt: new Date().toISOString(),
        metadata: {
          model: settings.currentModel,
          provider: settings.provider,
          exportVersion: "1.0"
        }
      };
      
      // 使用會話標題作為文件名的一部分
      const safeTitle = (session.title || 'untitled')
        .replace(/[^a-z0-9]/gi, '_')
        .toLowerCase();
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const fileName = `chat_${safeTitle}_${timestamp}.json`;
      
      // 創建並下載文件
      const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      
      // 觸發下載
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      
    } catch (error) {
      console.error('Export error:', error);
    }
  };

  const handleDelete = (sessionId: string) => {
    // 先從本地存儲中刪除
    StorageService.deleteSession(sessionId);
    
    // 立即更新 sessions state 以從側邊欄移除該會話
    setSessions(prevSessions => prevSessions.filter(session => session.id !== sessionId));
    
    // 如果刪除的是當前會話，清除當前會話
    if (currentSession === sessionId) {
      setCurrentSession(null);
    }
  };

  const handleStarChange = (sessionId: string, isStarred: boolean) => {
    // 更新 sessions state
    setSessions(prev => {
      const updatedSessions = prev.map(session => 
        session.id === sessionId 
          ? { ...session, isStarred, updatedAt: Date.now() }
          : session
      );
      return updatedSessions;
    });
  };

  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleSessionsReorder = (reorderedSessions: ChatSession[]) => {
    // 更新本地存儲中的順序
    reorderedSessions.forEach((session, index) => {
      const updatedSession = {
        ...session,
        order: index,
        updatedAt: Date.now() // 可選：更新時間戳
      };
      StorageService.saveChatSession(updatedSession);
    });
    
    // 直接更新狀態，不需要重新排序
    setSessions(reorderedSessions);
  };

  const handleSettings = () => {
    // 設定相關的邏輯
    setShowSettings(true);  // 假設你有一個 setShowSettings 狀態來控制設定面板的顯示
  };

  // 添加生成標題的函數
  const generateTitle = async (sessionId: string) => {
    const session = StorageService.getSession(sessionId);
    if (!session || !session.messages.length) return;

    try {
      // 使用最近的幾條消息來生成標題
      const recentMessages = session.messages.slice(-3);  // 使用最後3條消息
      
      const response = await fetch(`${settings.baseUrl}/v1/chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${settings.apiKey}`
        },
        body: JSON.stringify({
          model: settings.currentModel,
          messages: [
            {
              role: 'system',
              content: '你是一個標題生成器。請根據用戶的對話內容，生成一個簡短的中文標題（4-6個字）來概括這個對話的主題。請直接返回標題文字，不需要其他說明。'
            },
            {
              role: 'user',
              content: recentMessages.map(msg => `${msg.role}: ${msg.content}`).join('\n\n')
            }
          ],
          temperature: 0.7,
          max_tokens: 50
        })
      });

      if (!response.ok) {
        throw new Error('生成標題失敗');
      }

      const data = await response.json();
      const newTitle = data.choices[0].message.content.trim();
      
      // 更新標題
      handleTitleChange(sessionId, newTitle);
      
    } catch (error) {
      console.error('生成標題時出錯:', error);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const user = await verifySessionToken();
      if (user) {
        // 用戶已登入，載入設定等...
      }
    };
    
    checkAuth();
  }, []);

  const handleLogin = (newUser: User) => {
    setUser(newUser);
    // 可以在這裡載入使用者的設定等
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      // 可以在這裡清除使用者的設定等
    } catch (error) {
      console.error('登出失敗:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AppContainer>
        {showSidebar && (
          <Sidebar 
            onNewChat={createNewSession}
            currentSession={currentSession}
            setCurrentSession={setCurrentSession}
            isCollapsed={isSidebarCollapsed}
            setIsCollapsed={setIsSidebarCollapsed}
            sessions={sessions}
            onDeleteSession={handleDelete}
            onStarChange={handleStarChange}
            onSessionsReorder={handleSessionsReorder}
            user={user}
            onLogin={handleLogin}
            onLogout={handleLogout}
          />
        )}
        <MainContent>
          <ChatArea 
            sessionId={currentSession}
            settings={settings}
            isDarkMode={isDarkMode}
            setSettings={setSettings}
            onDelete={() => {
              if (currentSession) {
                handleDelete(currentSession);
              }
            }}
            onThemeToggle={() => setIsDarkMode(!isDarkMode)}
            onTitleChange={handleTitleChange}
            isSidebarCollapsed={isSidebarCollapsed}
            onRefreshTitle={(sessionId) => {
              handleTitleChange(sessionId, '新對話');
            }}
            sessions={sessions}
          />
        </MainContent>
        <ButtonGroup>
          {currentSession && (
            <IconButton
              onClick={() => generateTitle(currentSession)}
              title="重新生成標題"
            >
              <RefreshIcon />
            </IconButton>
          )}
          <IconButton 
            onClick={handleExport}
            title="導出對話"
          >
            <ExportIcon />
          </IconButton>
          <IconButton 
            onClick={() => setIsDarkMode(!isDarkMode)}
            title={isDarkMode ? "切換至亮色模式" : "切換至深色模式"}
          >
            {isDarkMode ? <SunIcon /> : <MoonIcon />}
          </IconButton>
          <IconButton 
            onClick={() => setShowSettings(true)}
            title="設定"
          >
            <SettingsIcon />
          </IconButton>
        </ButtonGroup>
        {showSettings && (
          <Settings 
            settings={settings}
            setSettings={setSettings}
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode}
            onClose={() => setShowSettings(false)}
          />
        )}
        <MobileNav>
          <MobileNavButton onClick={() => setShowSidebar(!showSidebar)}>
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
            對話列表
          </MobileNavButton>
          <MobileNavButton onClick={createNewSession}>
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <line x1="12" y1="5" x2="12" y2="19"/>
              <line x1="5" y1="12" x2="19" y2="12"/>
            </svg>
            新對話
          </MobileNavButton>
          <MobileNavButton onClick={() => setShowSettings(true)}>
            <SettingsIcon />
            設定
          </MobileNavButton>
        </MobileNav>
      </AppContainer>
    </ThemeProvider>
  );
};

export default App; 